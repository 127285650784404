import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../index.css";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: -30px;
  padding-top: 100px;
  z-index: 11;
  @media (max-width: 1024px) {
    padding-top: 0;
  }
`;

const RowWrapper = styled.div`
  margin-top: 6px;
`;

const Row = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 6px;
  border-bottom: 1px solid #bec2c8;
  text-decoration: none;
`;

const Text = styled.p`
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
`;

const Number = styled.span`
  font-size: 24px;
  font-weight: 500;
  margin-right: 20px;
  line-height: 24px;
  color: #0c0c0c;
`;
const SelectWrapper = styled.div`
  width: auto;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  height: auto;
  -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
`;
const Select = styled.select`
  color: #5a6270;
  width: 245px;
  background: #ffffff;
  border-radius: 3px;
  height: 41px;
  padding-left: 18px;
  border-right: 14px solid transparent;
  border-color: transparent;
`;

const AnchorBlock = ({ anchors }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const [selected, setSelected] = useState("");

  useEffect(() => {
    const value = anchors.find((an) => an.active);
    if (value) {
      setSelected(value.href);
    }
  }, [anchors]);

  const handleChange = (event) => {
    const path = event.target.value.split("#").pop();
    document.getElementById(path)?.scrollIntoView();
    setSelected(event.target.value);
  };

  return (
    <Wrapper>
      {isMobile ? (
        <SelectWrapper>
          <Select
            className="neueMachinaMedium"
            value={selected}
            onChange={handleChange}
          >
            {anchors.map((a, idx) => {
              return (
                <option key={a.href} value={a.href}>
                  {`0${idx + 1} ${a.title}`}
                </option>
              );
            })}
          </Select>
        </SelectWrapper>
      ) : (
        anchors.map((a, idx) => {
          return (
            <RowWrapper key={a.title}>
              <Row
                href={a.href}
                className={`${a.active ? "activeSideLink" : ""}`}
              >
                <Number className="neueMachinaMedium">0{idx + 1}</Number>
                <Text className="neueMachinaMedium">{a.title}</Text>
              </Row>
            </RowWrapper>
          );
        })
      )}
    </Wrapper>
  );
};
export default AnchorBlock;

import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import styled from "styled-components";
import MasterHero from "../components/MasterHero";
import MiddleLayout from "../components/MiddleLayout";
import Paragraph from "../components/Paragraph";
import BottomMasterBlock from "../components/BottomMasterBlock";
import AnchorBlock from "../components/AnchorBlock";
import Accordion from "../components/Accordion";

const Wrapper = styled.div`
  width: 100%;
`;

const SCROLL_GAP = 250;

const Culture = () => {
  const ref_1 = useRef();
  const ref_2 = useRef();
  const ref_3 = useRef();
  const ref_4 = useRef();
  const ref_5 = useRef();

  const [active_1, setActive_1] = useState(false);
  const [active_2, setActive_2] = useState(false);
  const [active_3, setActive_3] = useState(false);
  const [active_4, setActive_4] = useState(false);
  const [active_5, setActive_5] = useState(false);

  const trackScrolling = () => {
    setActive_1(
      ref_1.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    setActive_2(
      ref_2.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    if (ref_2.current.getBoundingClientRect().top - SCROLL_GAP <= 0) {
      setActive_1(false);
    }
    setActive_3(
      ref_3.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    if (ref_3.current.getBoundingClientRect().top - SCROLL_GAP <= 0) {
      setActive_2(false);
    }
    setActive_4(
      ref_4.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    if (ref_4.current.getBoundingClientRect().top - SCROLL_GAP <= 0) {
      setActive_3(false);
    }
    setActive_5(
      ref_5.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    if (ref_5.current.getBoundingClientRect().top - SCROLL_GAP <= 0) {
      setActive_4(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", trackScrolling);

    return () => {
      document.removeEventListener("scroll", trackScrolling);
    };
  }, []);

  const LeftSide = () => {
    return (
      <>
        <Paragraph
          outerRef={ref_1}
          title="Origin"
          text_1="Through the intersection of blockchain and zero-knowledge proofs, we are rebuilding the worlds economic infrastructure. Internet-native, permissionless value transfers executed on-chain. This technology will eliminate government overreach and give future generations a path forward for human rights."
          text_2="This is a significant undertaking – as the centralization of the world’s networks over the past decade has resulted in exploitation, oppression, and manipulation. Virtually every new technology has found itself entangled in the grasp of government regulation, its full potential limited, and its progress slowed."
          text_3="Charting the course for SIDDHA on a global scale is a marathon, not a sprint. It will take decades, but once realized, it will catalyze an unprecedented positive transformation for all of humanity."
          text_4="Enshrined within SIDDHA, you will discover our culture, personified through our Mission, Vision, and Values. Our future depends on the strength of this culture."
          text_5="Our values and beliefs shape who we are as a company. They guide us in everything we do, from making decisions to how we interact with each other."
          text_6="We hire, reward, and, when necessary, part ways, all based on our cultural principles. It's imperative to understand that our culture may not resonate with everyone. If you believe in what you read below, then you are likely to thrive here. If you do not, SIDDHA is not for you."
          id="origin"
        />
        <Paragraph
          outerRef={ref_2}
          title="Mission"
          boldText="SIDDHA’s mission to maximize human freedom through advanced code and mathematics."
          text_2="SIDDHA is fully devoted to its mission, relentlessly pursuing transformative change in the world."
          id="ourMission"
        />
        <Paragraph
          outerRef={ref_3}
          title="Vision"
          boldText="SIDDHA’s vision is to deploy permissionless value transfers on a global scale to solve our financial infrastructure challenges."
          text_2="We’re building a future where everyone has control over their financial lives, and where cryptography supports our freedom as the foundation of human rights."
          text_3="In our beginnings, we plan to build blockchain-based infrastructure on open networks. Harnessing these technologies - programmability, immutability, and traceability — we make transferring all assets more efficient, reliable, and secure. Over the long haul, the role of distributed ledgers will extend its reach far beyond its current boundaries, transforming payments and value movement worldwide."
          id="vision"
        />
        <Paragraph
          outerRef={ref_4}
          title="Values"
          text_1="To unearth the world's most motivated individuals, we seek those who relentlessly pursue perfection in their chosen paths, the world's most driven human beings, employees destined to be founders, those who have always felt that their genius has never been understood. Our culture is deliberately cultivated to amplify high performance and to push ourselves to operate at the best of our ability every day."
          text_2="We do not support social activism, make false assumptions, mistreat people, or advocate for causes unrelated to our mission. Our one and only goal is to provide consumers with a high-quality, impactful product."
          id="values"
        />
        <Accordion />
        <Paragraph
          outerRef={ref_5}
          title="Conclusion"
          text_1="At SIDDHA, our mission-driven and united culture empowers our team to make purposeful and impactful decisions."
          text_2="Join us in making a profound and lasting difference that shapes the world."
          id="conclusion"
          linkText
        />
        <BottomMasterBlock culture />
      </>
    );
  };

  const anchors = [
    { active: active_1, title: "ORIGIN", href: "#origin" },
    { active: active_2, title: "MISSION", href: "#ourMission" },
    { active: active_3, title: "VISION", href: "#vision" },
    { active: active_4, title: "VALUES", href: "#values" },
    { active: active_5, title: "CONCLUSION", href: "#conclusion" },
  ];

  return (
    <Wrapper>
      <MasterHero culture anchors={anchors} />
      <MiddleLayout
        leftSide={<LeftSide />}
        rightSide={<AnchorBlock anchors={anchors} />}
      />
      <Footer />
    </Wrapper>
  );
};
export default Culture;

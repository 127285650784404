import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Main from "./pages/Main";
import MasterPlan from "./pages/MasterPlan";
import AboutUs from "./pages/AboutUs";
import Culture from "./pages/Culture";
import React from "react";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" index element={<Main />} />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="master-plan" element={<MasterPlan />} />
      <Route path="culture" element={<Culture />} />
    </>
  )
);

function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;

import React, { useState, useCallback } from "react";
import styled from "styled-components";
import Collapsible from "react-collapsible";
import plus from "../assets/images/ButtonPlus.png";
import minus from "../assets/images/ButtonMinus.png";
import "../index.css";

const Wrapper = styled.div`
  width: 100%;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #bec2c8;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 30px;
  margin-top: 30px;
  justify-content: space-between;
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #bec2c8;
  cursor: pointer;
`;

const Text = styled.p`
  color: #0c0c0c;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  &:hover:after {
    width: 100%;
  }
  &:after {
    transition: all ease-in-out 0.2s;
    background: none repeat scroll 0 0 #0c0c0c;
    content: "";
    display: block;
    height: 1px;
    width: 0;
  }
`;
const Image = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: 10px;
  background-image: ${({ isOpen }) =>
    isOpen ? `url(${minus})` : `url(${plus})`};
  background-size: cover;
`;

const Number = styled.span`
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
  line-height: 24px;
  color: #0c0c0c;
`;
const Title = styled.p`
  font-size: 19px;
  font-weight: 600;
  line-height: 23px;
  color: #0c0c0c;
  margin-top: 60px;
  margin-bottom: 30px;
`;
const Desc = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #0c0c0c;
  margin-top: 0;
  margin-bottom: 42px;
`;

const Accordion = () => {
  const [openedNumbers, setOpenedNumbers] = useState([]);

  const onOpenList = useCallback((on) => {
    return () => {
      setOpenedNumbers((ons) => {
        const opened = [...ons];

        const index = ons.findIndex((o) => o === on);

        if (index !== -1) {
          opened.splice(index, 1);
        } else {
          opened.push(on);
        }

        return opened;
      });
    };
  }, []);

  const list = [
    {
      number: 1,
      text: "Product First, Mission Driven",
      desc: `Our product and mission are one and the same: to maximize human freedom through advanced code and mathematics. We are dedicated builders, designers, and engineers, unwavering in our mission to deliver an exceptional product.`,
    },
    {
      number: 2,
      text: "Boldness Is the Denominator",
      desc: `Hesitation creates obstacles, boldness eliminates them. With audacious action, we embrace the challenges ahead, knowing that the path to success won't be easy.`,
    },
    {
      number: 3,
      text: "Great Ideas are Priceless",
      desc: `Great ideas, fueled by courageous action, have the power to shape the world. We relentlessly pursue technical superiority and stay at the forefront of emerging developments, knowing that great ideas transform reality.`,
    },
    {
      number: 4,
      text: "Believe in Volume",
      desc: `For rapid growth and leveraging economies of scale, we prioritize meticulous planning and proactive development. We understand that success lies in mastering massive technical infrastructure and harnessing the power of big numbers`,
    },
    {
      number: 5,
      text: "Future Purpose, A Force Multiplier",
      desc: `Embracing a forward-looking mindset fuels exponential growth. Our unwavering focus lies in the accomplishments we can achieve 5, 10, 20+ years from now, prioritizing long-term impact over short-term victories.`,
    },
  ];

  return (
    <Wrapper>
      <Title className="groteskRoman">
        We guide our actions by these 5 core values:
      </Title>
      <Container>
        {list.map((l) => {
          const isOpen = openedNumbers.some((o) => o === l.number);

          return (
            <Block
              onClick={onOpenList(l.number)}
              key={l.number}
              isOpen={isOpen}
            >
              <Row>
                <LeftSide>
                  <Number className="groteskRoman">0{l.number}</Number>
                  <Text className="groteskRoman">{l.text}</Text>
                </LeftSide>

                <Image isOpen={isOpen} />
              </Row>
              <Collapsible open={isOpen}>
                <Desc className="groteskRoman">{l.desc}</Desc>
                {l.desc_1 ? (
                  <Desc className="groteskRoman">{l.desc_1}</Desc>
                ) : null}
              </Collapsible>
            </Block>
          );
        })}
      </Container>
    </Wrapper>
  );
};
export default Accordion;

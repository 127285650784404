import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import ArrowRightB from "../assets/images/arrow-right-b.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 140px 0 0;
  background: #fff;

  @media (max-width: 1024px) {
    padding: 45px 0 0;
  }
`;
const Container = styled.div`
  margin: 0 auto;
  width: 1530px;
  max-width: 100%;
  padding: 0 15px;
`;
const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
  align-items: center;
  border-bottom: 1px solid #000;
  padding: 0 0 140px;
  
  @media (max-width: 992px) {
    flex-direction: column;
    padding: 0 0 50px;
  }
`;
const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;

  @media (max-width: 992px) {
    margin-bottom: 60px;
  }
`;
const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
`;
const InnerLeftBlock = styled.div`
  max-width: 80%;

  @media (max-width: 992px) {
    max-width: 100% !important;
  }
`;
const Subtitle = styled.div`
  font-family: "InterMedium";
  font-weight: 500;
  line-height: 1.4;
  font-size: 40px;
  color: #1E2124;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    font-size: 24px;
  }
`;
const Title = styled.h2`
  font-family: "InterBold";
  font-weight: 700;
  line-height: 1.2;
  font-size: 56px;
  color: #1E2124;
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 32px;
  }
`;
const Text = styled.p`
  font-family: "InterRegular";
  font-weight: 400;
  line-height: 1.6;
  font-size: 24px;
  color: #000;
  margin: 0 0 15px 0;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
const LinkEl = styled.a`
  font-family: "InterMedium";
  font-weight: 500;
  line-height: 1.6;
  font-size: 32px;
  color: #B7B8B9;
  margin: 45px 0 0 0;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
  background: rgb(71,72,204,0);

  &:hover {
    color: transparent;
    background: -webkit-linear-gradient(90deg, rgba(71,72,204,1) 0%, rgba(128,129,224,1) 100%);
    background: linear-gradient(90deg, rgba(71,72,204,1) 0%, rgba(128,129,224,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &:hover img {
    transform: translateX(5px);
    transition: transform 0.2s ease-in-out;
    opacity: 1 !important;
  }

  @media (max-width: 992px) {
    margin: 20px 0 0 0;
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    text-decoration: none;
    -webkit-text-decoration: none;
  }
`;

const OurMission = () => {
  return (
    <Wrapper>
      <Container>
        <InnerWrapper>
          <LeftBlock>
            <InnerLeftBlock>
              <Subtitle>Our Mission</Subtitle>
              <Title>Maximize human freedom through advanced code and mathematics.</Title>
            </InnerLeftBlock>
          </LeftBlock>
          <RightBlock>
            <Text>Our monetary systems have changed.  How we defend them needs to change to.</Text>
            <Text>Security threats are evolving at machine speed. To keep pace, we pioneer solutions for the software-defined financial infrastructure of tomorrow.</Text>
            <LinkEl 
              href="#"
              className="disabled"
            >
              Learn more
              <img src={ArrowRightB} style={{opacity: "48%", marginLeft: "7px", transition: "transform 0.2s ease-in-out"}} />
            </LinkEl>
          </RightBlock>
        </InnerWrapper>
      </Container>
    </Wrapper>
  )
}
export default OurMission;
import React from "react";
import styled from "styled-components";
import lottieAnimation from "../assets/animations/lottie.json";
import Lottie from "lottie-react";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  background-color: #2f4c66;
  padding: 5vh 52px 24px 52px;
  position: relative;
  justify-content: space-between;
  @media (min-width: 200px) and (max-width: 700px) {
    padding: 45px 16px;
    height: auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  user-select: none;
  @media (min-width: 200px) and (max-width: 700px) {
    flex-direction: column-reverse;
    margin: 0;
    align-items: center;
  }
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: space-between;
  @media (min-width: 200px) and (max-width: 767px) {
    flex-basis: 100%;
    margin-bottom: 40px;
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    align-items: flex-start;
  }
`;
const ButtonsBlock = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const ImageBlock = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 50%;
  @media (min-width: 1221px) and (max-width: 1620px) {
    height: 70vh;
  }
  @media (min-width: 200px) and (max-width: 767px) {
    margin-bottom: 50px;
    height: 50vh;
  }
  @media (min-width: 1621px) and (max-width: 1920px) {
    height: 75vh;
  }
  @media (min-width: 1921px) {
    height: 80vh;
  }
`;

const BottomBlock = styled.div`
  width: 100%;
  margin-top: -9%;
  @media (min-width: 200px) and (max-width: 768px) {
    margin-top: auto;
  }
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 24px 0;
  background-color: #ffffff;
`;

const TextTitle = styled.h3`
  color: #ffffff;
  font-size: 80px;
  margin: 0;
  line-height: 125%;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  @media (min-width: 769px) and (max-width: 1023px) {
    font-size: 44px;
    line-height: 60px;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 40px;
    width: 100%;
  }
  @media (min-width: 1023px) and (max-width: 1280px) {
    font-size: 56px;
    width: 100%;
  }
`;
const TopText = styled.p`
  color: #ffffff;
  font-size: 40px;
  margin: 0;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  @media (min-width: 200px) and (max-width: 1440px) {
    font-size: 28px;
  }
`;
const SubBlock = styled.div`
  display: flex;
  flex-direction: row;
`;
const SubText = styled.p`
  color: #ffffff;
  font-family: "IBM Plex Mono", monospace;
  font-size: 16px;
  margin: 0;
  @media (min-width: 200px) and (max-width: 1024px) {
    font-size: 16px;
  }
  @media (min-width: 1025px) and (max-width: 1440px) {
    font-size: 13px;
  }
`;
const BottomText = styled.p`
  color: #ffffff;
  font-size: 18px;
  margin: 0;
  max-width: 765px;
  line-height: 125%;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
`;

const Hero = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      <Wrapper isMobile={isMobile}>
        <Content>
          <TextBlock>
            <ButtonsBlock>
              <TextTitle>zk-ROLLUPS</TextTitle>
              <TextTitle>Deployed to The</TextTitle>
              <TextTitle>Operational Edge</TextTitle>
            </ButtonsBlock>
          </TextBlock>
          <ImageBlock>
            <Lottie
              animationData={lottieAnimation}
              loop={true}
              style={{ height: "100%" }}
            />
          </ImageBlock>
        </Content>
        <BottomBlock>
          <SubBlock>
            <TopText>Scale</TopText>
            <SubText>01</SubText>
          </SubBlock>
          <SubBlock>
            <TopText>Rationalize</TopText>
            <SubText>02</SubText>
          </SubBlock>
          <SubBlock>
            <TopText>Standardize</TopText>
            <SubText>03</SubText>
          </SubBlock>
          <Line />
          <BottomText>
            A decentralized and secure zk-ROLLUP financial infrastructure
            protocol allowing infinite scalability.
          </BottomText>
        </BottomBlock>
      </Wrapper>
    </>
  );
};
export default Hero;

import React, { useState, useCallback } from "react";
import styled from "styled-components";
import founder from "../assets/images/founder.jpg";
import image_1 from "../assets/images/image_1.png";
import image_2 from "../assets/images/image_2.png";
import image_3 from "../assets/images/image_3.png";
import image_4 from "../assets/images/image_4.png";
import image_5 from "../assets/images/image_5.png";
import button from "../assets/images/button.svg";
import buttonActive from "../assets/images/button-active.svg";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  position: relative;
  padding: 160px 24px 10px 24px;
  background-color: #fff;
  @media (max-width: 768px) {
    padding: 50px 16px;
  }
  @media (min-width: 769px) and (max-width: 1023px) {
    padding: 64px 64px 0px 64px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  justify-content: space-between;
  z-index: 1;
  margin-bottom: 150px;
  @media (max-width: 1024px) {
    margin-bottom: 0;
  }
`;
const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
`;
const FounderCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 100px;
`;
const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;
const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  background-image: url(${button});
  background-size: contain;
  margin-top: 4px;
  margin-right: 4px;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 32.25%;
  padding: 25px;
  box-sizing: border-box;
  flex-shrink: 0;
  background: #eff6f6;
  border-radius: 10px;
  @media (min-width: 200px) and (max-width: 1024px) {
    margin-right: 25px;
  }
  &:hover {
    .button {
      background-image: url(${buttonActive});
      width: 54px;
      height: 54px;
      margin-top: 0;
      margin-right: 0;
    }
  }
`;
const MiddleCard = styled(Card)`
  margin: 0px 25px;
`;

const CardImage = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
`;
const Title = styled.p`
  color: #0c0c0c;
  font-size: 36px;
  font-weight: 500;
  font-family: "DisplayProRoman";
  margin: 0;
`;
const TitleBottom = styled(Title)`
  margin-top: 160px;
`;
const TextTitle = styled.h5`
  color: #0c0c0c;
  font-family: "DisplayProMedium";
  font-size: 22px;
  line-height: 36px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  width: 100%;
`;

const TextSubTitle = styled.p`
  color: #0c0c0c;
  font-size: 22px;
  line-height: 36px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  font-family: "DisplayProRoman";
  margin: 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;
const SliderRow = styled(Row)`
  margin: 80px 0 160px 0;
  justify-content: center;
  @media (min-width: 200px) and (max-width: 1024px) {
    margin: 80px 0 80px 0;
  }
`;
const SliderContainer = styled.div`
  max-width: 1677px;
  overflow: hidden;
`;
const Slider = styled.div`
  flex-direction: row;
  display: flex;
  overflow-x: auto;
  overflow-y: none;
  list-style: none;
  margin: 0;
  padding-bottom: 10px;
`;

const DATA = [
  {
    src: founder,
    name: "Murphy",
    title_1: "Founder & CEO",
    text_1:
      "Murphy is the Founder & CEO of Siddha Privacy, Inc. and a lifetime technology entrepreneur. Murphy has been building companies for over two decades, and now his sole focus is building Siddha with a 20-year view to positively impact the future of humanity.",
    text_2:
      "Prior to Siddha, Murphy founded and scaled high-growth companies across eCommerce, APIs, and artificial intelligence. Two achieved significant exits - one acquired by Overstock.com (Nasdaq: OSTK) and another acquired by (ERI), the largest cyber-security recycler in the U.S.",
    text_3:
      "Murphy founded Siddha with a clear mission: to maximize human freedom. Central to this mission is empowering individuals through cryptocurrency's original promise of being censorship-resistant, decentralized, and trustless.",
  },
  {
    src: image_1,
    name: "Vlad Novakovsky",
    title_1: "Cryptography Engineering",
    title_2: "Protocol Architect",
    text_1:
      "20+ years in security and cryptography. Lead builds at Haliburton in cryptography algorithms, cryptographic protocols, and public key infrastructure.",
  },
  {
    src: image_2,
    name: "Howard Poston",
    title_1: "Cryptographer and",
    title_2: "Protocol Developer",
    text_1:
      "Cybersecurity professional with a Masters of Cyber Operations from the US Air Force Institute of Technology (AFIT). Chief Information Security Officer: Qorra. Cybersecurity R&D S&E: Sandia National Laboratories",
  },
  {
    src: image_5,
    name: "Dr. Chen Feng",
    title_1: "R & D Scientist",
    text_1:
      "Dr. Chen Feng received the B.Eng. degree from the Department of Electronic and Communications Engineering, Shanghai Jiao Tong University, China, in 2006, and the M.A.Sc. and Ph.D. degrees from the Department of Electrical and Computer Engineering, University of Toronto, Canada, in 2009 and 2014, respectively.",
    text_2:
      "From 2014 to 2015, he was a Postdoctoral Fellow with Boston University, USA, and Ecole Polytechnique Federale de Lausanne (EPFL), Switzerland. He joined the School of Engineering, University of British Columbia, Kelowna, Canada, in July 2015, where he is currently a Professor and Principal’s Research Chair in Blockchain. He is lead of Blockchain@UBC.",
  },
  {
    src: image_4,
    name: "Nancy Wojtas",
    title_1: "Chief Legal Officer",
    text_1:
      "Specializing in venture capital and bank financings, proxy contests, cryptocurrency offerings, and going private transactions.",
    text_2:
      "Nancy began her career with the Securities and Exchange Commission in the division of Market Regulation and later served as counsel to the Chairman of the Commission. She is chair of the Blockchain Technology & Tokenization group.",
  },
  {
    src: image_3,
    name: "Ying Tong",
    title_1: "Zero-Knowledge",
    title_2: "Core Developer",
    text_1:
      "Applied cryptographer and researcher. Physicist by training and previously contributed to the Zcash and Ethereum projects. Author and maintainer of the halo2 library. Contributed to education and outreach efforts with halo2.",
  },
];

const Team = ({ onPerson }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [visible, setVisible] = useState(false);

  const onData = useCallback(
    (d) => {
      onPerson(d);
      setVisible(!visible);
    },
    [onPerson, visible]
  );

  return (
    <Wrapper>
      <Container id="team">
        {isMobile ? (
          <>
            <Title>Team:</Title>
            <SliderRow>
              <SliderContainer>
                <Slider>
                  <Card onClick={() => onData(DATA[0])}>
                    <CardImage src={DATA[0].src} alt="founder" />
                    <CardRow>
                      <CardColumn>
                        <TextTitle>{DATA[0].name}</TextTitle>
                        <TextSubTitle>{DATA[0].title_1}</TextSubTitle>
                      </CardColumn>
                      <ButtonWrapper className="button" />
                    </CardRow>
                  </Card>
                  <Card onClick={() => onData(DATA[1])}>
                    <CardImage src={DATA[1].src} alt="image_1" />
                    <CardRow>
                      <CardColumn>
                        <TextTitle>{DATA[1].name}</TextTitle>
                        <TextSubTitle>{DATA[1].title_1}</TextSubTitle>
                        <TextSubTitle>{DATA[1].title_2}</TextSubTitle>
                      </CardColumn>
                      <ButtonWrapper className="button" />
                    </CardRow>
                  </Card>
                  <Card onClick={() => onData(DATA[2])}>
                    <CardImage src={DATA[2].src} alt="image_2" />
                    <CardRow>
                      <CardColumn>
                        <TextTitle>{DATA[2].name}</TextTitle>
                        <TextSubTitle>{DATA[2].title_1}</TextSubTitle>
                        <TextSubTitle>{DATA[2].title_2}</TextSubTitle>
                      </CardColumn>
                      <ButtonWrapper className="button" />
                    </CardRow>
                  </Card>
                  <Card onClick={() => onData(DATA[3])}>
                    <CardImage src={DATA[3].src} alt="image_5" />
                    <CardRow>
                      <CardColumn>
                        <TextTitle>{DATA[3].name}</TextTitle>
                        <TextSubTitle>{DATA[3].title_1}</TextSubTitle>
                      </CardColumn>
                      <ButtonWrapper className="button" />
                    </CardRow>
                  </Card>
                  <Card onClick={() => onData(DATA[4])}>
                    <CardImage src={DATA[4].src} alt="image_4" />
                    <CardRow>
                      <CardColumn>
                        <TextTitle>{DATA[4].name}</TextTitle>
                        <TextSubTitle>{DATA[4].title_1}</TextSubTitle>
                      </CardColumn>
                      <ButtonWrapper className="button" />
                    </CardRow>
                  </Card>
                  <Card onClick={() => onData(DATA[5])}>
                    <CardImage src={DATA[5].src} alt="image_3" />
                    <CardRow>
                      <CardColumn>
                        <TextTitle>{DATA[5].name}</TextTitle>
                        <TextSubTitle>{DATA[5].title_1}</TextSubTitle>
                        <TextSubTitle>{DATA[5].title_2}</TextSubTitle>
                      </CardColumn>
                      <ButtonWrapper className="button" />
                    </CardRow>
                  </Card>
                </Slider>
              </SliderContainer>
            </SliderRow>
          </>
        ) : (
          <>
            <Title>Originally conceptualized by:</Title>
            <FounderCardWrapper>
              <CardsWrapper>
                <Card onClick={() => onData(DATA[0])}>
                  <CardImage src={DATA[0].src} alt="founder" />
                  <CardRow>
                    <CardColumn>
                      <TextTitle>{DATA[0].name}</TextTitle>
                      <TextSubTitle>{DATA[0].title_1}</TextSubTitle>
                    </CardColumn>
                    <ButtonWrapper className="button" />
                  </CardRow>
                </Card>
                <MiddleCard onClick={() => onData(DATA[1])}>
                  <CardImage src={DATA[1].src} alt="image_1" />
                  <CardRow>
                    <CardColumn>
                      <TextTitle>{DATA[1].name}</TextTitle>
                      <TextSubTitle>{DATA[1].title_1}</TextSubTitle>
                      <TextSubTitle>{DATA[1].title_2}</TextSubTitle>
                    </CardColumn>
                    <ButtonWrapper className="button" />
                  </CardRow>
                </MiddleCard>
                <Card onClick={() => onData(DATA[2])}>
                  <CardImage src={DATA[2].src} alt="image_2" />
                  <CardRow>
                    <CardColumn>
                      <TextTitle>{DATA[2].name}</TextTitle>
                      <TextSubTitle>{DATA[2].title_1}</TextSubTitle>
                      <TextSubTitle>{DATA[2].title_2}</TextSubTitle>
                    </CardColumn>
                    <ButtonWrapper className="button" />
                  </CardRow>
                </Card>
              </CardsWrapper>
            </FounderCardWrapper>
            <TitleBottom>Team:</TitleBottom>
            <FounderCardWrapper>
              <CardsWrapper>
                <Card onClick={() => onData(DATA[3])}>
                  <CardImage src={DATA[3].src} alt="image_5" />
                  <CardRow>
                    <CardColumn>
                      <TextTitle>{DATA[3].name}</TextTitle>
                      <TextSubTitle>{DATA[3].title_1}</TextSubTitle>
                    </CardColumn>
                    <ButtonWrapper className="button" />
                  </CardRow>
                </Card>
                <MiddleCard onClick={() => onData(DATA[4])}>
                  <CardImage src={DATA[4].src} alt="image_4" />
                  <CardRow>
                    <CardColumn>
                      <TextTitle>{DATA[4].name}</TextTitle>
                      <TextSubTitle>{DATA[4].title_1}</TextSubTitle>
                    </CardColumn>
                    <ButtonWrapper className="button" />
                  </CardRow>
                </MiddleCard>
                <Card onClick={() => onData(DATA[5])}>
                  <CardImage src={DATA[5].src} alt="image_3" />
                  <CardRow>
                    <CardColumn>
                      <TextTitle>{DATA[5].name}</TextTitle>
                      <TextSubTitle>{DATA[5].title_1}</TextSubTitle>
                      <TextSubTitle>{DATA[5].title_2}</TextSubTitle>
                    </CardColumn>
                    <ButtonWrapper className="button" />
                  </CardRow>
                </Card>
              </CardsWrapper>
            </FounderCardWrapper>
          </>
        )}
      </Container>
    </Wrapper>
  );
};
export default Team;

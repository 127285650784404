import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  background-color: #ffffff;
  gap: 8rem;
  @media (max-width: 1024px) {
    gap: 0;
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  flex-basis: 66%;
`;
const RightSide = styled.div`
  flex-basis: 34%;
`;

const MiddleLayout = ({ leftSide, rightSide }) => {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  return (
    <Wrapper>
      <LeftSide>{leftSide}</LeftSide>
      {!isMobile ? <RightSide>{rightSide}</RightSide> : null}
    </Wrapper>
  );
};
export default MiddleLayout;

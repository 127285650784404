import React from "react";
import styled from "styled-components";
import "../index.css";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 42px;
  border-top: 1px solid #bec2c8;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 42px;
  margin-top: 42px;
  border-bottom: 1px solid #bec2c8;
`;

const Text = styled.p`
  color: #0c0c0c;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
`;

const Number = styled.span`
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
  line-height: 24px;
  color: #0c0c0c;
`;
const ConclusionBlock = () => {
  return (
    <Wrapper>
      <Row>
        <Number className="groteskRoman">01</Number>
        <Text className="groteskRoman">
          Build Efficient Cross-Chain Bridges: Main protocol connecting banks
          on-chain.
        </Text>
      </Row>
      <Row>
        <Number className="groteskRoman">02</Number>
        <Text className="groteskRoman">
          Infrastructure for high growth segments of payments: Consolidating
          FinTech.
        </Text>
      </Row>
      <Row>
        <Number className="groteskRoman">03</Number>
        <Text className="groteskRoman">
          Standardize global transfers: Connecting all systems.
        </Text>
      </Row>
    </Wrapper>
  );
};
export default ConclusionBlock;

import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import styled from "styled-components";
import MasterHero from "../components/MasterHero";
import MiddleLayout from "../components/MiddleLayout";
import Paragraph from "../components/Paragraph";
import Opportunities from "../components/Opportunities";
import ConclusionBlock from "../components/ConclusionBlock";
import BottomMasterBlock from "../components/BottomMasterBlock";
import AnchorBlock from "../components/AnchorBlock";

const Wrapper = styled.div`
  width: 100%;
`;

const SCROLL_GAP = 250;

const MasterPlan = () => {
  const ref_1 = useRef();
  const ref_2 = useRef();
  const ref_3 = useRef();
  const ref_4 = useRef();
  const ref_5 = useRef();
  const ref_6 = useRef();
  const ref_7 = useRef();
  const ref_8 = useRef();
  const [active_1, setActive_1] = useState(false);
  const [active_2, setActive_2] = useState(false);
  const [active_3, setActive_3] = useState(false);
  const [active_4, setActive_4] = useState(false);
  const [active_5, setActive_5] = useState(false);
  const [active_6, setActive_6] = useState(false);
  const [active_7, setActive_7] = useState(false);
  const [active_8, setActive_8] = useState(false);

  const trackScrolling = () => {
    setActive_1(
      ref_1.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    setActive_2(
      ref_2.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    if (ref_2.current.getBoundingClientRect().top - SCROLL_GAP <= 0) {
      setActive_1(false);
    }
    setActive_3(
      ref_3.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    if (ref_3.current.getBoundingClientRect().top - SCROLL_GAP <= 0) {
      setActive_2(false);
    }
    setActive_4(
      ref_4.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    if (ref_4.current.getBoundingClientRect().top - SCROLL_GAP <= 0) {
      setActive_3(false);
    }
    setActive_5(
      ref_5.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    if (ref_5.current.getBoundingClientRect().top - SCROLL_GAP <= 0) {
      setActive_4(false);
    }
    setActive_6(
      ref_6.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    if (ref_6.current.getBoundingClientRect().top - SCROLL_GAP <= 0) {
      setActive_5(false);
    }
    setActive_7(
      ref_7.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    if (ref_7.current.getBoundingClientRect().top - SCROLL_GAP <= 0) {
      setActive_6(false);
    }
    setActive_8(
      ref_8.current.getBoundingClientRect().top - SCROLL_GAP <= 0 ? true : false
    );
    if (ref_8.current.getBoundingClientRect().top - SCROLL_GAP <= 0) {
      setActive_7(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", trackScrolling);

    return () => {
      document.removeEventListener("scroll", trackScrolling);
    };
  }, []);

  const LeftSide = () => {
    return (
      <>
        <Paragraph
          outerRef={ref_1}
          boldTitle="Our Mission"
          boldText="SIDDHA’s mission to maximize human freedom through advanced code and mathematics."
          id="mission"
        />
        <Paragraph
          outerRef={ref_2}
          title="Ethos"
          id="ethos"
          ethos
          subTitle="Opinion: The Enemy of Truth"
          divider_1
        />
        <Paragraph
          outerRef={ref_3}
          title="The Company"
          subTitle="Talent hits a target no one else can hit"
          subTitle_2="Genius hits a target no one else can see"
          text_1="In a world where fiat currencies reign supreme and the concept of freedom faces constant threats, there is undoubtedly a myriad of causes worth championing. We firmly believe blockchains offer an unprecedented opportunity to establish robust systems in defense of these values. Our focus is rooted in the ideals of human freedom that are foundational to our society. We felt there were no great companies taking on the status quo and seeking truth – so we decided to be that company."
          text_2="SIDDHA's goal is to deploy censorship-resistant, permissionless value transfers on-chain. Our innovative approach, coupled with Zero-Knowledge technology, allows us to merge how humans exchange value in a way that was once thought impossible."
          text_3="The build will take decades — require a world-class team dedicated to the mission, billions of dollars invested, and engineering innovation in order to achieve mass-market impact. We face high risk and extremely low chances of success. However, if we are successful, we have the potential to positively impact humanity and to build the largest company on the planet."
          id="company"
          divider_2
        />
        <Paragraph
          outerRef={ref_4}
          title="The Shift"
          subTitle="2020: 'Argument by Authority'"
          subTitle_2="2023: 'Argument from Cryptography'"
          text_1="Every decade, the internet has been transformed by improvements in encryption. It's happening again, and this next wave is reshaping the world’s financial systems."
          text_2="We live on encrypted emails, messages, and websites... And now we're on the last mile to the holy grail of encrypted blockchains. The core technology of blockchain encryption is cryptography: and inside of that are zero-knowledge proofs. With ZKP, a party can prove the truth of a statement to another party without divulging any additional information, making it a game-changer for the blockchain space."
          text_3="Even though this sounds somewhat like magic, this type of encryption has been around for 30+ years. Only recent advancements have enabled zero-knowledge encryption to function on the web and be verified by a blockchain. Privacy, security, scalability, interoperability, and sovereign identity are all set to undergo radical changes due to developments in zero-knowledge (ZKP) technology."
          text_4="Instead of just sending encrypted data we can now send encrypted value. This gives us the ability to encrypt value transfers with the most secure cryptography ever invented. Argument from cryptography begins superseding argument from authority."
          text_5="This may sound straightforward, but the implications are staggering."
          id="shift"
          divider_2
        />
        <Paragraph
          outerRef={ref_5}
          title="The Opportunity"
          subTitle="Infrastructure: The New World"
          subTitle_2="The Problem"
          boldStart="Our financial systems are vanishing: "
          text_1="Seven billion people now live under a devalued currency or autocracy. A Trillion dollars left banks over the past six months. The tokenized volume of bonds and equities market will reach $27 Trillion by 2027."
          boldStart_2="Payment infrastructure is dead: "
          text_2="Traditional payments don’t work. Crypto payments aren’t better."
          text_3="This new world has magnified our broken money architecture."
          text_4="This has turned one of the largest global industries into the least liquid. Leaving trillions up for grabs, and no one is paying attention."
          text_5="These stuck payment rails were the one spot that we saw the most opportunity.  The foundational layer in the stack where we could exert maximum leverage."
          id="opportunity"
          divider_1
        />
        <Paragraph
          subTitle_2="The Position"
          text_1="Fortunately, we are at the forefront of a blockchain revolution, which offers us a distinctive opportunity to significantly enhance our production and quality of life."
          text_2="The blockchain is an incorruptible digital ledger of economic transactions that can be programmed to record not just financial transactions but virtually everything of value. The applications for a public, verifiable ledger of transaction information are nearly limitless. Blockchain makes an equitable future a reality by irreversibly capturing history, allowing for the massive storage and dissemination of data."
        />
        <Paragraph
          subTitle_2="Frictionless Liquidity"
          text_1="SIDDHA is navigating a world where liquidity has now become paramount."
          text_2="Just like Web2 needed TCP/IP to connect isolated islands of computer networks, web3 needs an interoperability standard to connect islands of blockchain networks. Yet, it's more than just connecting blockchains; it's weaving the fabric of Web2, Web3, traditional finance, and crypto into a cohesive ecosystem where value transcends borders and is transferred instantaneously."
        />
        <Opportunities />
        <Paragraph
          outerRef={ref_6}
          title="The Solution"
          subTitle="What The Market Cannot Define – The Market Cannot Control"
          boldStart_2="Regulatory Intervention "
          boldStart_3="Market Driven Solutions "
          text_1="There are two prevailing mindsets regarding the deteriorating state of our current financial systems:"
          text_2="naively assumes that simply by fortifying and revising existing regulatory frameworks, the ongoing decay of the financial system can be halted."
          text_3="are blindly optimistic, placing undue faith in the decentralized essence of markets. They believe that mere competitive forces, combined with consumer demands, are the magic bullet for fostering more resilient financial structures."
          text_4="Both these schools of thought are tragically misguided. They're engaged in a futile exercise, trying to patch and hack the status quo. Banking on blockchain alone as their salvation is delusional.  They will stay in their swim lanes - We’re going after the bigger opportunity, building an entirely new model, that makes all previous obsolete."
          text_5="Siddha is the first-of-its-kind value transfer company, bringing frictionless liquidity for global commerce. Instead of touching the banking rails, wasting time country by country – we’re instantly globally available. Instead of long confusing wallet addresses – create your personal ENS name. Instead of gas in a currency you aren't using – its fixed fee in USDC. No addresses. No chains. Lightning-fast transactions. One integration, limitless bridges. The liquidity rails for modern capital."
          text_6="The new connective tissue of the world lies in humans’ ability to lower uncertainty and transfer value –we’re building the liquidity software to do just that."
          id="solution"
          divider_1
        />
        <Paragraph
          outerRef={ref_7}
          title="Strategy"
          subTitle="The Winner-Takes-All Effects in Real-Time Clearing"
          text_1="The foundation of the internet was laid in 1983 with the Transmission Control Protocol (TCP), facilitating data transfer online. Siddha's technology mirrors this role, but for transfer of value instead of mere data. The potential market for this surpasses the combined size of all internet companies twentyfold. In my two decades of studying and founding companies, never have I seen a market with such colossal potential. Arriving there will require significant advancements in technology."
          text_2="We’re heads-down and focused on making substantive leaps in those areas of advancement."
          id="strategy"
          divider_1
        />
        <Paragraph subTitle_2="Systemic Solution" divider_2 />
        <Paragraph
          subTitle_2="REDUCE: Overcapacity"
          text_1="Efficient Cross-Chain Bridges: Amidst the complexities of global payment flows, we understand the indispensable nature of cross-chain bridges. Yet, simply linking public blockchains together only gets one half-way there. Traditional finance will create their own chains that give them complete control over their own tokenized real-world assets. We’re applying advanced cryptographic techniques and mathematical principles to enhance transaction finality. This fosters a continuous learning process to discover each winner-takes-all effect that lie inside of digital clearing. This sets Siddha up as the main connection, as the inevitable trillions pours onto the blockchain from banks."
        />
        <Paragraph
          subTitle_2="STABILIZE: Prices"
          text_1="Comprehensive Fintech Solutions: Siddha's engineering plan encompasses the consolidation of diverse fintech services into a unified platform. This transformation in financial technology focuses on streamlining fees, reducing market volatility, enhancing financial security, and optimizing asset management. This is where high volume becomes critical in the pursuit to reach economies of scale."
          text_2="Fintech has been streamlining the $5 Trillion real-time payments market. These players will stick to their models. We’re going after the bigger market that has opened.  Combining all the high growth, fast moving segments (Platforms, Online, Wallets, Embedded and Real Time.) that have reached $55 Trillion."
        />
        <Paragraph
          subTitle_2="RATIONALIZE: Industry"
          text_1="Standardizing Global Transfer: Siddha engineers the standardization of the global transfer ecosystem, eliminating redundancy and consolidating the blockchain landscape into a fast, efficient, and unified network. Our focus on a standardized fee structure ensures transparency and reliability, connecting all systems, traditional or modern. This positions Siddha as the global Visa of digital real-time clearing."
        />
        <Paragraph
          outerRef={ref_8}
          title="Conclusion"
          text_1="The first phase of our Master Plan:"
          id="conclusion"
        />
        <ConclusionBlock />
        <BottomMasterBlock />
      </>
    );
  };

  const anchors = [
    { active: active_1, title: "OUR MISSION", href: "#mission" },
    { active: active_2, title: "ETHOS", href: "#ethos" },
    { active: active_3, title: "THE COMPANY", href: "#company" },
    { active: active_4, title: "THE SHIFT", href: "#shift" },
    { active: active_5, title: "THE OPPORTUNITY", href: "#opportunity" },
    { active: active_6, title: "THE SOLUTION", href: "#solution" },
    { active: active_7, title: "STRATEGY", href: "#strategy" },
    { active: active_8, title: "CONCLUSION", href: "#conclusion" },
  ];

  return (
    <Wrapper>
      <MasterHero anchors={anchors} />
      <MiddleLayout
        leftSide={<LeftSide />}
        rightSide={<AnchorBlock anchors={anchors} />}
      />
      <Footer />
    </Wrapper>
  );
};
export default MasterPlan;

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import cancel from "../assets/images/cancel.png";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: flex;
  justify-content: flex-end;
  background-color: hsla(0, 0%, 5%, 0.2);
`;
const Container = styled.div`
  transition: transform 0.3s ease-in-out;
  position: relative;
  width: 100%;
  max-width: 494px;
  background-color: #fbfbfb;
  padding: 25px;
  overflow-x: auto;
  animation-duration: 0.5s;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Button = styled.img`
  width: 30px;
  height: 30px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 4.2rem;
  height: 4.2rem;
  color: #0c0c0c;
  background-color: #fff;
  border-radius: 0.4rem;
  border: none;
  cursor: pointer;
  z-index: 10;
  width: 42px;
  height: 42px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  &:hover {
    background: #eff6f6;
  }
`;
const ButtonContainer = styled.div`
  position: sticky;
  width: -webkit-fill-available;
  display: flex;
  justify-content: flex-end;
  top: 0;
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
  background: #eff6f6;
  border-radius: 10px;
  width: 100%;
  padding: 25px;
`;

const CardImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
`;
const Title = styled.p`
  color: #0c0c0c;
  font-size: 53px;
  font-weight: 400;
  font-family: "DisplayProRoman";
  margin: 0 0 50px 0;
  text-transform: uppercase;
  @media (max-width: 380px) {
    font-size: 46px;
  }
  @media (min-width: 381px) and (max-width: 400px) {
    font-size: 50px;
  }
`;

const TextSubTitle = styled.p`
  color: #0c0c0c;
  font-size: 22px;
  line-height: 36px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  font-family: "DisplayProRoman";
  margin: -25px 0 0 0;
`;
const Text = styled.p`
  color: #0c0c0c;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  font-family: "DisplayProRoman";
  margin-top: 40px;
  margin-bottom: 0;
`;

const Modal = ({ data, isVisible, setVisible }) => {
  const [status, setStatus] = useState(isVisible);

  useEffect(() => {
    if (isVisible) {
      setStatus(true);
    } else {
      setTimeout(function () {
        setStatus(false);
      }, 150);
    }
  }, [isVisible]);

  const onVisible = useCallback(() => {
    setVisible(false);
  }, [setVisible]);
  return (
    <>
      {status ? (
        <Wrapper onClick={onVisible}>
          <Container
            id="teamModal"
            isVisible={isVisible}
            className={
              isVisible
                ? "animate__animated animate__fadeInRight"
                : "animate__animated animate__fadeOutRight"
            }
          >
            <Content>
              <ButtonContainer>
                <ButtonWrapper onClick={onVisible}>
                  <Button src={cancel} />
                </ButtonWrapper>
              </ButtonContainer>

              <TextSubTitle>{data.title_1}</TextSubTitle>
              {data.title_2 ? (
                <TextSubTitle style={{ marginTop: 0 }}>
                  {data.title_2}
                </TextSubTitle>
              ) : null}
              <Title>{data.name}</Title>
              <Card>
                <CardImage src={data.src} alt="founder" />
              </Card>
              <Text>{data.text_1}</Text>
              {data.text_2 ? <Text>{data.text_2}</Text> : null}
              {data.text_3 ? <Text>{data.text_3}</Text> : null}
            </Content>
          </Container>
        </Wrapper>
      ) : null}
    </>
  );
};
export default Modal;

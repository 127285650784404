import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import "../index.css";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #0c0c0c;
`;
const SubTitle = styled.p`
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 30px;
  margin-top: 20px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  padding: 0 24px;
  margin-top: 130px;
  @media (min-width: 320px) and (max-width: 1024px) {
    margin-top: 80px;
  }
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 1024px) {
    max-width: 68%;
  }
`;

const TopTextTitle = styled.h3`
  color: #ffffff;
  font-size: 42px;
  font-weight: 500;
  margin: 0;
  line-height: 1;
  @media (min-width: 768px) {
    font-size: 60px;
  }
  @media (min-width: 1280px) {
    font-size: 85px;
  }
`;

const TextTitle = styled.span`
  font-size: 42px;
  font-weight: 500;
  margin: 0;
  line-height: 1;
  background: linear-gradient(90deg, #2888be 0%, #34b2a7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  @media (min-width: 768px) {
    font-size: 60px;
  }
  @media (min-width: 1280px) {
    font-size: 85px;
  }
`;
const Desc = styled.p`
  color: #ffffff;
  font-size: 19px;
  line-height: 160%;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 35px;
  letter-spacing: -0.5px;
  max-width: 82%;
`;

const InsertText = styled.p`
  color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  margin: 30px 0;
  margin: ${({ culture }) => {
    return culture ? "30px 0 75px 0" : "30px 0";
  }};
`;
const Dot = styled.span`
  border-radius: 6px;
  width: 3px;
  height: 3px;
  background: #ffffff;
  display: inline-block;
  margin-bottom: 3px;
  margin-left: 4px;
  margin-right: 4px;
`;

const MasterHero = ({ culture, anchors }) => {
  return (
    <Wrapper>
      <Header anchors={anchors} />
      <Content>
        <SubTitle className="groteskMedium">
          {culture ? "Culture at SIDDHA" : " Master Plan"}
        </SubTitle>
        <TextBlock>
          <TopTextTitle className="neueMachinaMedium">
            {culture ? "SIDDHA WAS" : "A POSITIVE FUTURE"}
          </TopTextTitle>

          {culture ? (
            <TopTextTitle className="neueMachinaMedium">
              FOUNDED TO MAKE THIS WORLD BETTER BY INCREASING PEOPLE'S{" "}
              <TextTitle className="neueMachinaMedium">FREEDOM</TextTitle>
            </TopTextTitle>
          ) : (
            <TextTitle className="neueMachinaMedium">
              POWERED BY IMMUTABLE CODE AND MATHEMATICS
            </TextTitle>
          )}

          <InsertText className="groteskMedium" culture={culture}>
            By: M. Murphy, Founder & CEO <Dot /> May 19, 2023
          </InsertText>
          {!culture ? (
            <Desc className="groteskRoman">
              Background: I’m 20 years into building technology companies, two
              included significant exits - one acquired by Overstock.com
              (Nasdaq: OSTK) and one acquired by (ERI) the largest
              cyber-security recycler in the U.S. My final focus is SIDDHA. My
              intention is to build this company with a long-term vision
              spanning two decades, fully immersing myself in maximizing the
              impact we can make on humanity.
            </Desc>
          ) : null}
        </TextBlock>
      </Content>
    </Wrapper>
  );
};
export default MasterHero;

import React from "react";
import styled from "styled-components";
import "../index.css";
import founder from "../assets/images/founder.jpg";
import signature from "../assets/images/sihgn.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 42px;
  padding-bottom: 120px;
`;
const DescBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 24px;
  justify-content: center;
`;

const Title = styled.span`
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;
const Text = styled.p`
  color: #0c0c0c;
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  margin-bottom: 30px;
  margin-top: 0;
`;
const Desc = styled.span`
  color: #979797;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;
const FounderImage = styled.div`
  width: 115px;
  height: 115px;
  background-image: url(${founder});
  background-size: cover;
  background-position: 50%;
  flex-shrink: 0;
`;
const Signature = styled.div`
  width: 80px;
  height: 39px;
  background-image: url(${signature});
  background-size: cover;
  background-position: 50%;
  flex-shrink: 0;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const BottomMasterBlock = ({ culture }) => {
  return (
    <Wrapper>
      {!culture ? (
        <Text className="groteskRoman">
          The immutable laws of code and mathematics have advanced so that we
          can improve the lives of billions. We now have the potential to change
          the course of history and maximize human freedom.
        </Text>
      ) : null}
      <Row>
        <FounderImage />
        <DescBlock>
          <Signature />
          <Title className="DisplayProMedium">Murphy</Title>
          <Desc className="groteskRoman">Founder & CEO</Desc>
        </DescBlock>
      </Row>
    </Wrapper>
  );
};
export default BottomMasterBlock;

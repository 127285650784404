import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Header from "./Header";
import heroVideo from "../assets/video/hero-video.mp4"
import { useMediaQuery } from "react-responsive";
import InputBlockNew from "./InputBlockNew";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
  justify-content: space-end;
  overflow: hidden;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  user-select: none;
  animation-duration: 1s;
  animation-delay: 0.2s;
`;
const WrapperContainer = styled.div`
  position: absolute;
  bottom: 64px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 2;

  @media (max-width: 1024px) {
    height: 100%;
    bottom: 0;
  }
`;
const Container = styled.div`
  margin: 0 auto;
  width: 1530px;
  max-width: 100%;
  padding: 0 15px;

  @media (max-width: 1024px) {
    height: 100%;
  }
`;
const ContentBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 1024px) {
    height: 100%;
  }
`;
const TextTitle = styled.h3`
  color: #ffffff;
  font-size: 40px;
  line-height: 1.2;
  margin: 0;
  font-family: "InterMedium";
  font-weight: 500;
  max-width: 70%;

  @media (max-width: 810px) {
    max-width: 100%;
    margin: auto;
  }
  @media (min-width: 1024px) and (max-width: 1440px){
    max-width: 90%;
    margin: auto;
  }
  @media (min-width: 320px) and (max-width: 1023px) {
    font-size: 32px;
    margin: auto;
  }
`;
const VideoBackgroundS = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;
const VideoEl = styled.div`
  position: relative;
  width: 100%;

  video {
    background: rgba(0, 0, 0, 0.8);
    -webkit-filter: grayscale(100%) brightness(50%) contrast(100%);
    filter: grayscale(100%) brightness(50%) contrast(100%);
  }
  @media (max-width: 1024px) {
    height: 801px;
    video {
      object-fit: cover;
    }
  }
`;
const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  
  @media (max-width: 1024px) {
    margin-top: 105px;
  }
`;
const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;

  @media (max-width: 1024px) {
    margin: 0 !important;
    justify-content: end;
  }

  @media (max-width: 1024px) {
    margin: 30px 0;
  }
`;

const HeroVideo = ({ christmas }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [isChristmas, setIsChristmas] = useState();

  const turnOff = useCallback(() => {
    setIsChristmas(isChristmas);
    christmas(false);
  }, [christmas, isChristmas]);

  return (
    <>
      <Header top={28}  />
      <Wrapper isMobile={isMobile}>
        <Content className="animate__animated animate__fadeIn">
          <VideoBackgroundS />
          <VideoEl>
            <video src={heroVideo} width="100%" height="100%" autoPlay loop muted playsInline></video>
          </VideoEl>
          <WrapperContainer>
            <Container>
              <ContentBlock>
                <LeftBlock>
                  <TextTitle>
                    Siddha is the first-of-its-kind blockchain company connecting the world to modern rails
                  </TextTitle>
                </LeftBlock>
                <RightBlock>
                  <InputBlockNew />
                </RightBlock>
              </ContentBlock>
            </Container>
          </WrapperContainer>
        </Content>
      </Wrapper>
    </>
  );
};

export default HeroVideo;

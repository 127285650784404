import React from "react";
import styled from "styled-components";
import logo from "../assets/images/logo-medium.svg";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  position: relative;
  background-color: #110f13;
  display: flex;
  flex-direction: column;
  padding-bottom: 120px;
  width: 100%;
  overflow: hidden;
  @media (min-width: 200px) and (max-width: 767px) {
    padding-bottom: 60px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-bottom: 140px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  width: 100%;
`;
const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`;

const Text = styled.p`
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  font-family: "DisplayProRoman";
  margin: 0;
  z-index: 1;
  max-width: 1190px;
  text-align: center;
  @media (min-width: 200px) and (max-width: 1024px) {
    width: 95%;
    font-size: 24px;
    line-height: 40px;
    padding: 0 16px;
    text-align: left;
  }
`;

const Logo = styled.img`
  width: 816px;
  height: 813px;
  @media (max-width: 767px) {
    width: 273px;
    height: 273px;
  }
  @media (min-width: 767px) and (max-width: 1023px) {
    width: 544px;
    height: 544px;
  }
`;

const IntroImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 816px;
  height: 813px;
  position: absolute;
  top: 73px;
  @media (max-width: 768px) {
    width: auto;
    height: auto;
  }
`;
const TextTitle = styled.h3`
  z-index: 1;
  width: 100%;
  margin: 0;
  font-family: "NeueMachinaMedium";
  font-weight: 500;
  font-size: 85px;
  line-height: 82px;
  text-transform: uppercase;
  line-height: 1;
  color: #ffffff;
  span {
    background: linear-gradient(90.32deg, #2888be 0.2%, #33aea9 99.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  @media (min-width: 200px) and (max-width: 767px) {
    font-size: 38px;
    line-height: 45px;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 46px;
    line-height: 50px;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 68px;
    line-height: 70px;
  }
`;

const TextContent = styled.div`
  margin: 274px 0 202px 0;
  z-index: 1;
  width: 1190px;
  @media (max-width: 767px) {
    width: auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  @media (min-width: 1025px) and (max-width: 1180px) {
    width: 1000px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
`;

const Goal = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Wrapper>
      <Content>
        <TextBlock>
          <IntroImage>
            <Logo src={logo} alt="logo" />
          </IntroImage>
          <TextContent>
            <TextTitle>OUR GOAL IS TO</TextTitle>
            <TextTitle style={{ textAlign: "right" }}>
              DEPLOY PERMISSIONLESS
            </TextTitle>
            <TextTitle>VALUE TRANSFERS</TextTitle>
            <TextTitle style={{ textAlign: "right" }}>TO SUPPORT US</TextTitle>
            {isMobile ? (
              <>
                <TextTitle>
                  ON A
                  <TextTitle style={{ textAlign: "right" }}>
                    <span>GLOBAL SCALE.</span>
                  </TextTitle>{" "}
                </TextTitle>
              </>
            ) : (
              <TextTitle>
                <TextTitle>
                  {" "}
                  ON A <span>GLOBAL SCALE.</span>
                </TextTitle>
              </TextTitle>
            )}
          </TextContent>
        </TextBlock>
        <Text>
          As the Global Monetary Order disintegrates a new financial
          infrastructure will appear. This tectonic shift will allow ways to
          exchange value to a degree which humanity has never seen.
        </Text>
      </Content>
    </Wrapper>
  );
};
export default Goal;

import React, { useCallback } from "react";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";
import InputBlock from "./InputBlock";
import { NavLink, useLocation } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 140px auto 0;
  background: #1E2124;
  @media (max-width: 1024px) {
    flex-direction: column;
    margin: 45px auto 0;
  }
`;
const Logo = styled.a`
  font-size: 94px;
  font-weight: 600;
  line-height: 134px;
  color: #ffffff;
  letter-spacing: 0.25em;
  cursor: pointer;
  text-decoration: none;
  width: min-content;
  @media (min-width: 320px) and (max-width: 360px) {
    font-size: 60px;
  }
  @media (min-width: 361px) and (max-width: 1024px) {
    font-size: 65px;
  }
`;
const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: space-between;
`;
const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  margin-left: 100px;
  @media (max-width: 1024px) {
    margin: 30px 0;
  }
`;
const LinkBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: 300ms;
  border-bottom: 1px solid transparent;
`;
const LinkNumber = styled.span`
  font-family: "SplineSansMono";
  color: #ffffff;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  margin-right: 24px;
`;
const LinkRow = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 20px;
  text-decoration: none;
  p {
    @media (max-width: 460px) {
      font-size: 36px;
    }
  }
`;
const Link = styled.p`
  color: #ffffff;
  font-size: 56px;
  line-height: 1.2;
  margin: 0;
  &:hover:after {
    width: 100%;
  }
  &:after {
    transition: all ease-in-out 0.2s;
    background: none repeat scroll 0 0 #ffffff;
    content: "";
    display: block;
    height: 2px;
    width: 0;
  }
`;
const MobileBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin-top: 19px;
  // margin-bottom: 19px;
  margin: 70px 0 100px;
`;
const Container = styled.div`
  margin: 0 auto;
  width: 1530px;
  max-width: 100%;
  padding: 0 15px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 120px 24px 60px 24px;
  
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1024px) {
    padding: 10px 0;
  }
`;
const ContentBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Copyright = styled.p`
  font-family: "InterRegular";
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
  margin: 0;
  
  @media (max-width: 1024px) {
    display: none;
  }
`;
const CopyrightMob = styled.p`
  font-family: "InterRegular";
  font-weight: 400;
  font-size: 24px;
  line-height: 20px;
  color: #ffffff;
  width: 100%;
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background: #bec2c8;
`;

const Footer = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { pathname } = useLocation();

  const toTop = useCallback(() => {
    if (pathname) {
      document.querySelector("html").scrollTop = 0;
    }
  }, [pathname]);

  return (
    <Wrapper>
      <Container>
        <Content>
          <ContentBlock>
            <LeftBlock>
              <Logo href="/">SIDDHA</Logo>
              <InputBlock />
              <Copyright>© Siddha Protocol {new Date().getFullYear()}</Copyright>
              {isMobile && (
                <MobileBottom>
                  <LinkBlock>
                    <>
                      <LinkRow
                        className="footerLink interFont"
                        to="/about-us"
                        onClick={toTop}
                      >
                        <LinkNumber>01</LinkNumber>
                        <Link>ABOUT US</Link>
                      </LinkRow>
                      <Line />
                    </>
                    <>
                      <LinkRow
                        className="footerLink interFont"
                        to="/master-plan"
                        onClick={toTop}
                      >
                        <LinkNumber>02</LinkNumber>
                        <Link>MASTER PLAN</Link>
                      </LinkRow>
                      <Line />
                    </>
                    <>
                      <LinkRow
                        className="footerLink interFont"
                        to="/culture"
                        onClick={toTop}
                      >
                        <LinkNumber>03</LinkNumber>
                        <Link>CULTURE</Link>
                      </LinkRow>
                      <Line />
                    </>
                    <>
                      <LinkRow
                        className="footerLink interFont"
                        to="https://angel.co/company/siddha/jobs"
                        target="_blank"
                      >
                        <LinkNumber>04</LinkNumber>
                        <Link>CAREERS</Link>
                      </LinkRow>
                      <Line />
                    </>
                  </LinkBlock>
                </MobileBottom>
              )}
            </LeftBlock>
            {!isMobile ? (
              <RightBlock>
                <LinkBlock>
                  <>
                    <LinkRow
                      className="footerLink interFont"
                      to="/about-us"
                      onClick={toTop}
                    >
                      <LinkNumber>01</LinkNumber>
                      <Link>ABOUT US</Link>
                    </LinkRow>
                    <Line />
                  </>
                  <>
                    <LinkRow
                      className="footerLink interFont"
                      to="/master-plan"
                      onClick={toTop}
                    >
                      <LinkNumber>02</LinkNumber>
                      <Link>MASTER PLAN</Link>
                    </LinkRow>
                    <Line />
                  </>
                  <>
                    <LinkRow
                      className="footerLink interFont"
                      to="/culture"
                      onClick={toTop}
                    >
                      <LinkNumber>03</LinkNumber>
                      <Link>CULTURE</Link>
                    </LinkRow>
                    <Line />
                  </>
                  <>
                    <LinkRow
                      className="footerLin interFont"
                      to="https://angel.co/company/siddha/jobs"
                      target="_blank"
                    >
                      <LinkNumber>04</LinkNumber>
                      <Link>CAREERS</Link>
                    </LinkRow>
                    <Line />
                  </>
                </LinkBlock>
              </RightBlock>
            ) : null}
          </ContentBlock>
          <CopyrightMob>© Siddha Protocol {new Date().getFullYear()}</CopyrightMob>
        </Content>
      </Container>
    </Wrapper>
  );
};
export default Footer;

import React, { useCallback, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  height: 42px;
  width: 42px;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
`;
const StyledHamburger = styled.div`
  height: 100%;
  width: 38px;
  align-items: center;
  display: flex;
  justify-content: center;
`;
const Wrapper = styled.div`
  background-color: transparent;
  display: block;
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  width: 24px;
  height: 24px;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
`;
const LineTop = styled.div`
  display: block;
  position: absolute;
  left: 0;
  width: 105%;
  height: 2px;
  background: ${({ color }) => color};
  transition-duration: 0.3s, 0.3s;
  transition-delay: ${({ closed }) => (closed ? "0, 0.5s" : "0.5s, 0s")};
  top: ${({ closed }) => (closed ? "11px" : 0)};
  transition-property: top, transform;
  transform: ${({ closed }) => (closed ? "rotate(-315deg)" : null)};
`;
const LineMiddle = styled.div`
  display: block;
  position: absolute;
  top: 10.5px;
  left: 0;
  right: 0;
  height: 2px;
  background: ${({ closed, color }) => (closed ? "transparent" : color)};
  transition-duration: 0.3s, 0.3s;
  transition-delay: ${({ closed }) => (closed ? " 0s, 0.5s" : "0.5s, 0s")};
`;
const LineBottom = styled.div`
  display: block;
  position: absolute;
  left: 0;
  width: 105%;
  height: 2px;
  background: ${({ color }) => color};
  transition-duration: 0.3s, 0.3s;
  transition-delay: ${({ closed }) => (closed ? "0, 0.5s" : "0.5s, 0s")};
  bottom: ${({ closed }) => (closed ? "11px" : 0)};
  transition-property: bottom, transform;
  transform: ${({ closed }) => (closed ? "rotate(315deg)" : null)};
`;

const Hamburger = ({ onClick, closed }) => {
  const hamburgerColor = "#0C0C0C";
  const [check, setCheck] = useState(closed);

  const onPressHandler = useCallback(() => {
    onClick();
    setCheck(!check);
  }, [onClick, check]);

  return (
    <Container onClick={onPressHandler}>
      <StyledHamburger>
        <Wrapper>
          <LineTop closed={closed} color={hamburgerColor} />
          <LineMiddle closed={closed} color={hamburgerColor} />
          <LineBottom closed={closed} color={hamburgerColor} />
        </Wrapper>
      </StyledHamburger>
    </Container>
  );
};

export default Hamburger;

import React, { useCallback, useState } from "react";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import styled from "styled-components";
import Rollups from "../components/Rollups";
import MineHero from "../components/MineHero";
import HighSpeed from "../components/HighSpeed";
import HeroVideo from "../components/HeroVideo";
import OurMission from "../components/OurMission"
import TwoColumns from "../components/TwoColumns";
import FooterNew from "../components/FooterNew";

const Wrapper = styled.div`
  width: 100%;
  background: #fff;

  @media (max-width: 767px) {
    overflow: hidden;
  }
`;

const Main = () => {
  const [isChristmas, setIsChristmas] = useState();

  const christmas = useCallback((isChristmas) => {
    setIsChristmas(isChristmas);
  }, []);

  return !isChristmas ? (
    <Wrapper>
      <HeroVideo />
      <OurMission />
      <TwoColumns />
      <FooterNew />
    </Wrapper>
  ) : (
    <h1 className="center" style={{ color: "#fff", textAlign: "center" }}>
      Merry Christmas!
    </h1>
  );
};
export default Main;

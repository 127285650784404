import styled from "styled-components";

import standardized from "../assets/images/standardized.svg";
import rationalize from "../assets/images/rationalize.svg";
import scale from "../assets/images/scale.svg";

const Wrapper = styled.div`
  background-color: #110f13;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  @media (min-width: 320px) and (max-width: 768px) {
    margin: 0 auto;
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  padding: 90px 40px 0 40px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding: 0 0 25px 0;
    margin: 0;
    padding: 0 16px;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;
const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: hidden;
  max-width: 1366px;
  margin: 0 auto;
`;
const TopBlockRow = styled(Row)`
  padding: 16px 0 16px 24px;
  flex-shrink: 0;
`;
const SubTitleRow = styled(Row)`
  padding: 0 34px;
  margin-top: 24px;
`;
const ImageRow = styled(Row)`
  padding: 5px 20px 20px 0;
  justify-content: flex-end;
`;
const SliderRow = styled(Row)`
  margin: 80px 0 160px 0;
  justify-content: center;
  @media (max-width: 767px) {
    margin: 80px 0;
  }
`;
const SliderContainer = styled.div`
  max-width: 1677px;
  overflow: hidden;
`;
const Slider = styled.div`
  flex-direction: row;
  display: flex;
  overflow-x: auto;
  overflow-y: none;
  list-style: none;
  margin: 0;
  padding-bottom: 10px;
`;
const TextTop = styled.p`
  font-size: 16px;
  color: #dbf0ff;
  font-family: "IBM Plex Mono", monospace;
  font-weight: 900;
  margin-right: 5px;
  flex-shrink: 0;
  line-height: 64px;
`;

const NetBlock = styled.div`
  width: 19px;
  height: 8px;
  border-right: 1px solid #dbf0ff;
  flex-shrink: 0;
`;

const BlockColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 442px;
  height: 437px;
  background: #202830;
  border-radius: 24px;
  flex-shrink: 0;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    width: 307px;
    height: 490px;
  }
`;
const Stroke = styled.div`
  width: 100%;
  height: 1px;
  background: #dbf0ff;
  border-radius: 24px;
`;

const Image = styled.img`
  width: 160px;
  height: 160px;
`;

const TextTitle = styled.h3`
  color: #dbf0ff;
  font-size: 76px;
  font-weight: 900;
  font-family: "Titillium Web", sans-serif;
  text-align: center;
  margin: 44px auto 60px auto;
  width: 100%;
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 15px;
  }
`;

const Text = styled.p`
  color: #dbf0ff;
  font-size: 56px;
  line-height: 120%;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  margin: 5px auto;
  text-align: center;
  width: 100%;
  max-width: 1366px;
  @media (max-width: 767px) {
    font-size: 24px;
  }
`;
const BlockTitle = styled.p`
  color: #dbf0ff;
  font-size: 32px;
  line-height: 125%;
  font-weight: 400;
  font-family: "Titillium Web", sans-serif;
  position: relative;
  flex-shrink: 0;
  width: 80%;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 25px;
  }
`;

const SubText = styled.span`
  color: #dbf0ff;
  font-family: "IBM Plex Mono", monospace;
  font-size: 16px;
  margin: 0;
  position: absolute;
  margin-left: 3px;
`;
const SubTitle = styled.p`
  color: #dbf0ff;
  font-size: 20px;
  line-height: 125%;
  font-weight: 400;
  font-family: "Titillium Web", sans-serif;
  width: 100%;
  margin: 0;
`;

const Rollups = () => {
  return (
    <Wrapper>
      <Content>
        <TopRow>
          <TextTop>CORE CONCEPT</TextTop>
          <Row>
            {Array(100)
              .fill()
              .map((a, index) => {
                return <NetBlock key={index} />;
              })}
          </Row>
        </TopRow>
        <TextTitle>Precise Compression</TextTitle>
        <Text>
          Building the end game of real-time transfers with zk-ROLLUPS
        </Text>
        <SliderRow>
          <SliderContainer>
            <Slider>
              <Block>
                <TopBlockRow>
                  <BlockTitle>
                    Scale without compromise<SubText>01</SubText>
                  </BlockTitle>
                </TopBlockRow>
                <Stroke />
                <BlockColumn>
                  <SubTitleRow>
                    <SubTitle>
                      Siddha’s longitudinal roll-ups guarantee instant finality.
                    </SubTitle>
                  </SubTitleRow>
                  <ImageRow>
                    <Image src={scale} alt="scale" width={160} height={160} />
                  </ImageRow>
                </BlockColumn>
              </Block>
              <Block style={{ margin: "0 20px" }}>
                <TopBlockRow>
                  <BlockTitle>
                    Rationalize Ethereum compatibility<SubText>02</SubText>
                  </BlockTitle>
                </TopBlockRow>
                <Stroke />
                <BlockColumn>
                  <SubTitleRow>
                    <SubTitle>
                      Seamlessly align your asset interactions and participate
                      effortlessly within the Ethereum ecosystem, optimizing
                      both functionality and cost-effectiveness.
                    </SubTitle>
                  </SubTitleRow>
                  <ImageRow>
                    <Image
                      src={rationalize}
                      alt="rationalize"
                      width={160}
                      height={160}
                    />
                  </ImageRow>
                </BlockColumn>
              </Block>
              <Block>
                <TopBlockRow>
                  <BlockTitle>
                    Standardized interoperability<SubText>03</SubText>
                  </BlockTitle>
                </TopBlockRow>
                <Stroke />
                <BlockColumn>
                  <SubTitleRow>
                    <SubTitle>
                      Shared across multiple rollups, making transfers and
                      bridging cheaper, faster, and safer.
                    </SubTitle>
                  </SubTitleRow>
                  <ImageRow>
                    <Image
                      src={standardized}
                      alt="standardized"
                      width={160}
                      height={160}
                    />
                  </ImageRow>
                </BlockColumn>
              </Block>
            </Slider>
          </SliderContainer>
        </SliderRow>
      </Content>
    </Wrapper>
  );
};
export default Rollups;

import styled from "styled-components";

import fast from "../assets/images/fast.png";
import clear from "../assets/images/clear.png";
import liquid from "../assets/images/liquid.png";

const Wrapper = styled.div`
  background-color: #110f13;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  @media (min-width: 320px) and (max-width: 768px) {
    margin: 0 auto;
    flex-direction: column;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  padding: 90px 40px 0 40px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    margin: 0;
    padding: 45px 16px 0 16px;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;
const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: hidden;
  max-width: 1366px;
  margin: 0 auto;
`;
const ImageRow = styled(Row)`
  justify-content: center;
`;
const SliderRow = styled(Row)`
  margin: 80px 0 160px 0;
  justify-content: center;
  @media (max-width: 767px) {
    margin: 80px 0;
  }
`;
const SliderContainer = styled.div`
  max-width: 1677px;
  overflow: hidden;
`;
const Slider = styled.div`
  flex-direction: row;
  display: flex;
  overflow-x: auto;
  overflow-y: none;
  list-style: none;
  margin: 0;
  padding-bottom: 10px;
`;

const NetBlock = styled.div`
  width: 19px;
  height: 8px;
  border-right: 1px solid #e0f9ff;
  flex-shrink: 0;
`;

const BlockColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 442px;
  height: 437px;
  background: #1c2a36;
  border-radius: 24px;
  flex-shrink: 0;
  margin-bottom: 10px;
  @media (max-width: 767px) {
    width: 307px;
    height: 365px;
  }
`;
const Stroke = styled.div`
  width: 120px;
  height: 10px;
  margin: 10px 0 36px 0;
`;

const Image = styled.img`
  width: 375px;
  height: 225px;
  @media (max-width: 767px) {
    width: 258px;
    height: 160px;
  }
`;

const TextTitle = styled.h3`
  color: #e0f9ff;
  font-size: 76px;
  font-weight: 900;
  line-height: normal;
  font-family: "Titillium Web", sans-serif;
  text-align: center;
  line-height: 120%;
  margin: 44px auto 60px auto;
  width: 100%;
  max-width: 1300px;
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 15px;
  }
`;

const Text = styled.p`
  color: #e0f9ff;
  font-size: 56px;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  margin: 5px auto;
  text-align: center;
  width: 100%;
  @media (max-width: 767px) {
    font-size: 24px;
  }
`;
const BlockTitle = styled.p`
  color: #e0f9ff;
  font-size: 34px;
  font-weight: 900;
  font-family: "Titillium Web", sans-serif;
  flex-shrink: 0;
  text-transform: uppercase;
  margin: 0;
`;

const SubTitle = styled.p`
  color: #e0f9ff;
  font-size: 20px;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
`;

const HighSpeed = () => {
  return (
    <Wrapper>
      <Content>
        <TopRow>
          <Row>
            {Array(100)
              .fill()
              .map((a, index) => {
                return <NetBlock key={index} />;
              })}
          </Row>
        </TopRow>
        <TextTitle>Build High-Speed, Secure Value Transfers</TextTitle>
        <Text>One Integration, Limitless Bridges.</Text>
        <SliderRow>
          <SliderContainer>
            <Slider>
              <Block>
                <BlockColumn>
                  <ImageRow>
                    <Image src={fast} alt="fast" />
                  </ImageRow>
                  <BlockTitle>fast</BlockTitle>
                  <Stroke style={{ backgroundColor: "#9ED5DF" }} />
                </BlockColumn>
                <SubTitle>Integrate in Minutes, transact immediately</SubTitle>
              </Block>
              <Block style={{ margin: "0 20px" }}>
                <BlockColumn>
                  <ImageRow>
                    <Image src={clear} alt="clear" />
                  </ImageRow>
                  <BlockTitle>clear</BlockTitle>
                  <Stroke style={{ backgroundColor: "#FCBB7A" }} />
                </BlockColumn>
                <SubTitle>A single control center, deployed anywhere</SubTitle>
              </Block>
              <Block>
                <BlockColumn>
                  <ImageRow>
                    <Image src={liquid} alt="liquid" />
                  </ImageRow>
                  <BlockTitle>LIQUID</BlockTitle>
                  <Stroke style={{ backgroundColor: "#D1B6D9" }} />
                </BlockColumn>
                <SubTitle>
                  Accelerate conversion with transaction finality
                </SubTitle>
              </Block>
            </Slider>
          </SliderContainer>
        </SliderRow>
      </Content>
    </Wrapper>
  );
};
export default HighSpeed;

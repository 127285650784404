import React, { useCallback, useState } from "react";
import styled from "styled-components";
import "../index.css";
import spin from "../assets/animations/spin.gif";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-top: 56px;
  @media (max-width: 1024px) {
    margin-top: 0;
  }
`;
const InputWrapper = styled.div`
  width: 485px;
  justify-content: center;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    margin-bottom: 30px;
    width: 345px;
  }
`;
const Input = styled.input`
  width: 458px;
  height: 56px;
  border-radius: 8px;
  flex-shrink: 0;
  background-color: rgba(255,255,255,0.78);
  padding-left: 24px;
  border: none;
  font-size: 16px;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255,255,255,1);
    transition: 0.2s ease-in-out;
  }
  @media (max-width: 1024px) {
    width: 319px !important;
  }
  @media (min-width: 413px) and (max-width: 767px) {
    width: 310px;
  }
`;
const Text = styled.p`
  color: #ffffff;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 24px;
`;
const Title = styled(Text)`
  margin-bottom: 8px;
`;
const Build = styled.span`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
`;
const Button = styled.button`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  background: #4748CC;
  border-radius: 4px;
  width: 108px;
  height: 48px;
  flex-shrink: 0;
  margin-left: -115px;
  cursor: pointer;
  border: none;
  position: absolute;
  top: 5px;
`;
const SpinWrapper = styled.span`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: absolute;
  right: 41px;
  top: 9px;
  overflow: hidden;
`;
const SpinImage = styled.img`
  width: 100%;
  height: 100%;
  background-image: url(${spin});
  background-size: cover;
`;
const Fr = styled.iframe`
  display: none;
`;
const Hidden = styled.div`
  margin-top: 57px;
`;

const InputBlock = () => {
  const [spin, setSpin] = useState(false);
  const [show, setShow] = useState(false);

  const Spin = useCallback(() => {
    setSpin(true);
    setTimeout(() => {
      setSpin(false);
      setShow(true);
    }, 1500);
  }, []);

  return (
    <Wrapper className="interFont">
      <Fr name="myIFR" />
      {!show ? <Text>Keep up with us.</Text> : null}
      <InputWrapper>
        {show ? (
          <Hidden>
            <Title>Let’s build.</Title>
            <Build>Thanks for signing up.</Build>
          </Hidden>
        ) : (
          <form onSubmit={Spin} target="myIFR">
            <Input
              required
              placeholder="Email Address*"
              type="email"
              name="Email"
            />
            <Button type="submit" value="Send">
              {spin ? (
                <SpinWrapper>
                  <SpinImage />
                </SpinWrapper>
              ) : (
                "Sign up"
              )}
            </Button>
          </form>
        )}
      </InputWrapper>
    </Wrapper>
  );
};
export default InputBlock;

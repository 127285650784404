import React from "react";

import Anvil from "../assets/images/anvil.png";
import Ghost from "../assets/images/ghost.png";
import Vault from "../assets/images/vault.png";

const TwoColumnsContentOne = {
    image     : Vault,
    alt       : "Vault",
    title     : "Gateway to on-chain finance",
    subtitle  : "On-ramp for banks, asset managers, and financial services companies",
    linkText  : "Explore Vault"
};

const TwoColumnsContentTwo = {
  image     : Ghost,
  alt       : "Ghost",
  title     : "Advanced zero-knowledge on any blockchain.",
  subtitle  : "Move your shielded digital assets throughout the blockchain economy behind a wall of encrypted energy.",
  linkText  : "Explore Ghost"
};

const TwoColumnsContentThree = {
  image     : Anvil,
  alt       : "Anvil",
  title     : "Legitimize transactions | Authenticate tokens",
  subtitle  : "Infuse cryptographic truths into centralised assets leveraging our registration authority.",
  linkText  : "Explore Anvil"
};

export { TwoColumnsContentOne, TwoColumnsContentTwo, TwoColumnsContentThree };
import React, { useState, useCallback, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import siddha from "../assets/images/siddha.svg";
import siddhaWhite from "../assets/images/siddha-white.svg";
import { useMediaQuery } from "react-responsive";
import Hamburger from "./Hamburger";
import "../index.css";
import AnchorBlock from "./AnchorBlock";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 40px 24px;
  width: 100%;
  margin: 0 auto;
  z-index: 10;
  position: fixed;
  padding-top: ${(props) => (props.top ? `${props.top}px` : 40)};
  @media (min-width: 767px) and (max-width: 1024px) {
    padding: 30px 24px;
  }
`;
const WrapperMobile = styled.div`
  background-color: ${({ isOpen }) => (isOpen ? "#1E2124" : "transparent")};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  z-index: 10;
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const LinkWrapper = styled(NavLink)`
  margin-right: 8px;
  text-decoration: none;
  border-radius: 3px;
  &:hover {
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    // background: #225d87;
    background: #4748CC;
    color: white;
    border-radius: 3px;
  }
`;
const Link = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 10px;
  gap: 8px;
  background-color: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  text-decoration: none;
  color: #0c0c0c;
  font-weight: 600;
  font-size: 14px;
  border: inset 1.5px solid transparent;

  &:hover {
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    // background: #225d87;
    background: #4748CC;
    color: white;
  }
`;

const ContainerMain = styled.div`
  margin: 0 auto;
  width: 1530px;
  max-width: 100%;
  padding: 0 15px;
`;

const Container = styled.div`
  background: #1E2124;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0px 16px;
  transition: max-height 1s ease;
  height: 100vh;
`;


const LinkList = styled.div`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 45px;
  width: 100%;
  display: flex;
`;

const TimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
    margin: 0;
    font-size: 12px;
    line-height: 13px;
    color: #e0e4e9;
  }
`;
const Timer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90px;
  user-select: none;
  span {
    color: #e0e4e9;
    font-size: 12px;
    margin-bottom: 0px;
    line-height: 13px;
  }
`;

const LinkContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
`;

const LinkItem = styled(NavLink)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 30px;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  font-weight: 800;
  font-size: 26px;
  line-height: 41px;
  border-bottom: 1px solid #bec2cb;
`;

const LogoImage = styled.div`
  width: 28px;
  height: 26px;
  background-image: url(${siddha});
  background-size: cover;
  &:hover {
    background-image: url(${siddhaWhite});
  }
`;
const Select = styled.div`
  width: auto;
`;
const LogoMobile = styled.a`
  width: 42px;
  height: 42px;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: flex;
  flex-shrink: 0;
  &:hover {
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    // background: #225d87;
    background: #4748CC;
  }
`;
const Logos = styled(LogoMobile)`
  margin-right: 8px;
`;
const Text = styled.p`
  color: #ffffff;
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
  margin: 0;
`;

const Number = styled.span`
  font-size: 24px;
  font-weight: 500;
  margin-right: 24px;
  line-height: 1;
  color: #ffffff;
`;
const MobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 10;
  animation-delay: 1.7s;
`;

const Header = ({ anchors, top, turnOff }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const [isOpen, setIsOpen] = useState(false);

  const { pathname } = useLocation();

  const toTop = useCallback(() => {
    if (pathname) {
      document.querySelector("html").scrollTop = 0;
    }
  }, [pathname]);

  const onOpenList = useCallback(
    (show) => {
      setIsOpen(show ? isOpen : !isOpen);
      toTop();
    },
    [isOpen, toTop]
  );

  var deadline = "2023-12-24";

  function getTimeRemaining(endtime) {
    var t = Date.parse(endtime) - Date.parse(new Date());
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {
      total: t,
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }

  const [isVisible, setIsVisible] = useState(false);

  setTimeout(function () {
    setIsVisible(!isVisible);
  }, 1000);

  // useEffect(() => {
  //   if (getTimeRemaining(deadline).total <= 0) {
  //     turnOff(true);
  //   }
  // }, [deadline, turnOff]);

  return (
    <React.Fragment>
      {isMobile ? (
        <MobileMenu>
          <WrapperMobile isOpen={isOpen}>
            <LogoMobile onClick={toTop} href="/">
              <LogoImage src={siddha} alt="logo" />
            </LogoMobile>
            {anchors && !isOpen ? (
              <Select>
                <AnchorBlock anchors={anchors} />
              </Select>
            ) : null}
        
            <Hamburger closed={isOpen} onClick={onOpenList} />
          </WrapperMobile>
          {isMobile && isOpen ? (
            <Container mobile={isMobile} isOpen={isOpen}>
              <LinkList>
                <LinkContainer>
                  <LinkItem
                    className="headerLink"
                    onClick={() => onOpenList(false)}
                    to="/about-us"
                  >
                    <Number className="splineSansMono">01</Number>
                    <Text className="interFont">ABOUT US</Text>
                  </LinkItem>
                  <LinkItem
                    className="headerLink"
                    onClick={() => onOpenList(false)}
                    to="/master-plan"
                  >
                    <Number className="splineSansMono">02</Number>
                    <Text className="interFont">MASTER PLAN</Text>
                  </LinkItem>
                  <LinkItem
                    className="headerLink"
                    onClick={() => onOpenList(false)}
                    to="/culture"
                  >
                    <Number className="splineSansMono">03</Number>
                    <Text className="interFont">CULTURE</Text>
                  </LinkItem>
                  <LinkItem
                    className="headerLink"
                    onClick={() => onOpenList(false)}
                    to="https://angel.co/company/siddha/jobs"
                    target="_blank"
                  >
                    <Number className="splineSansMono">04</Number>
                    <Text className="interFont">CAREERS</Text>
                  </LinkItem>
                </LinkContainer>
              </LinkList>
            </Container>
          ) : null}
        </MobileMenu>
      ) : (
        <Wrapper top={top}>
          <ContainerMain>
            <Links>
              <Logos style={{ height: 39, width: 39 }} href="/" onClick={toTop}>
                <LogoImage src={siddha} alt="logo" />
              </Logos>
              <LinkWrapper to="/about-us" onClick={toTop}>
                <Link>About Us</Link>
              </LinkWrapper>
              <LinkWrapper to="/master-plan" onClick={toTop}>
                <Link>Master Plan</Link>
              </LinkWrapper>
              <LinkWrapper to="/culture" onClick={toTop}>
                <Link>Culture</Link>
              </LinkWrapper>
              <LinkWrapper
                to="https://angel.co/company/siddha/jobs"
                target="_blank"
              >
                <Link>Careers</Link>
              </LinkWrapper>
            </Links>
            {isTablet && anchors ? (
              <Select>
                <AnchorBlock anchors={anchors} />
              </Select>
            ) : null}
          </ContainerMain>
        </Wrapper>
      )}
    </React.Fragment>
  );
};
export default Header;

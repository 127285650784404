import React from "react";
import styled from "styled-components";
import "../index.css";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #0c0c0c;
  margin-top: 30px;
  padding: 80px 40px 34px 40px;
  @media (max-width: 460px) {
    margin: 30px -24px;
    padding: 30px 20px;
    width: auto;
  }
`;
const SubTitle = styled.p`
  color: #ffffff;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 40px;
  line-height: 43px;
  @media (max-width: 460px) {
    font-size: 24px;
    width: 100%;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 46px;
`;
const BoxRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 46px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 26px;
  }
`;

const GradientBorderDiv = styled.div`
  min-width: 235px;
  width: 30%;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5px;
  background: linear-gradient(180deg, #2888be, #34b2a7);
  background-clip: padding-box, border-box;
  box-decoration-break: clone;
  position: relative;
  flex: 1;
  flex-shrink: 0;
  @media (max-width: 768px) {
    width: 100%;
    height: 182px;
    margin: 0 0 20px 0;
  }
  @media (min-width: 1730px) {
    height: 182px;
  }
`;
const MiddleGradientBorderDiv = styled(GradientBorderDiv)`
  margin: 0 10px 20px 10px;
  @media (max-width: 768px) {
    margin: 0 0 20px 0;
  }
`;
const GradientContent = styled.div`
  display: flex;
  padding: 22px 24px 12px 24px;
  flex-direction: column;
  background: #0c0c0c;
  height: 100%;
  flex: 1;
  @media (max-width: 768px) {
    width: 100%;
    padding: 22px 20px 12px 20px;
  }
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.p`
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  line-height: 17px;
`;

const BlockTitle = styled.p`
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  line-height: 26px;
  color: #ffffff;
  @media (max-width: 460px) {
    font-size: 18px;
    line-height: 22px;
  }
`;

const Opportunities = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <Wrapper className="groteskRoman">
      <SubTitle>Realized vision and long-term opportunities</SubTitle>
      <Row>
        {isMobile ? (
          <TextBlock>
            <Text>More Structured</Text>
            <Text>Less Variability</Text>
          </TextBlock>
        ) : (
          <>
            <TextBlock>
              <Text>More Structured</Text>
              <Text>Less Variability</Text>
            </TextBlock>
            <TextBlock>
              <Text>Less Structured</Text>
              <Text>More Variability</Text>
            </TextBlock>
          </>
        )}
      </Row>

      <BoxRow>
        <GradientBorderDiv>
          <GradientContent>
            <BlockTitle>Payments Market</BlockTitle>
            <Text style={{ marginTop: 24, marginBottom: 24 }}>
              Fastest growing
            </Text>
            <Text>Segments ($54 T)</Text>
          </GradientContent>
        </GradientBorderDiv>
        <MiddleGradientBorderDiv>
          <GradientContent>
            <BlockTitle>Global Payment Flows</BlockTitle>
            <Text style={{ marginTop: 24, marginBottom: 24 }}>($240T)</Text>
            <Text style={{ marginBottom: 24 }}>Fintech adoption</Text>
            <Text>Increase (39%)</Text>
          </GradientContent>
        </MiddleGradientBorderDiv>
        <GradientBorderDiv>
          <GradientContent>
            <BlockTitle>Standardize Global Transfers</BlockTitle>
            <Text style={{ marginTop: 24, marginBottom: 24 }}>
              Universal: Borderless transactions.
            </Text>
            <Text>Instant: Real-time global payments.</Text>
          </GradientContent>
        </GradientBorderDiv>
      </BoxRow>
      {isMobile ? (
        <Row>
          <TextBlock>
            <Text>Less Structured</Text>
            <Text>More Variability</Text>
          </TextBlock>
        </Row>
      ) : null}
    </Wrapper>
  );
};
export default Opportunities;

import React from "react";
import styled from "styled-components";
import { TwoColumnsContentOne, TwoColumnsContentTwo, TwoColumnsContentThree } from "../components/TwoColumnsContent";
import ArrowRightB from "../assets/images/arrow-right-b.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // padding: 140px 0 0;
  background: #fff;
  // border-bottom: 1px solid #000;
  // @media (max-width: 1024px) {
  //   padding: 45px 0 20px;
  // }
`;
const Container = styled.div`
  margin: 0 auto;
  width: 1530px;
  max-width: 100%;
  padding: 0 15px;
`;
const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
  align-items: center;
  border-bottom: 1px solid #000;
  padding: 140px 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 50px 0;
  }
`;
const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;

  @media (max-width: 1024px) {
    margin-bottom: 60px;
  }

  img {
    max-width: 85%;

    @media (max-width: 1024px) {
      max-width: 100%;
    }
  }
`;
const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Title = styled.h3`
  font-family: "InterMedium";
  font-weight: 500;
  line-height: 1.2;
  font-size: 48px;
  color: #1E2124;
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;
const Text = styled.p`
  font-family: "InterRegular";
  font-weight: 400;
  line-height: 1.6;
  font-size: 20px;
  color: #000;
  margin: 15px 0;

  @media (max-width: 767px) {
    font-size: 16px;
  }
`;
const LinkEl = styled.a`
  font-family: "InterMedium";
  font-weight: 500;
  line-height: 1.6;
  font-size: 32px;
  color: #B7B8B9;
  margin: 0;
  text-decoration: none;
  transition: transform 0.2s ease-in-out;
  background: rgb(71,72,204,0);

  &:hover {
    color: transparent;
    background: -webkit-linear-gradient(90deg, rgba(71,72,204,1) 0%, rgba(128,129,224,1) 100%);
    background: linear-gradient(90deg, rgba(71,72,204,1) 0%, rgba(128,129,224,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &:hover img {
    transform: translateX(5px);
    transition: transform 0.2s ease-in-out;
    opacity: 1 !important;
  }

  @media (max-width: 1024px) {
    margin: 20px 0 0 0;
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    text-decoration: none;
    -webkit-text-decoration: none;
  }
`;

const TwoColumns = () => {
  return (
      <Wrapper>
        <Container>
          <InnerWrapper>
            <LeftBlock>
              <img 
                src={TwoColumnsContentOne.image}
                alt={TwoColumnsContentOne.alt}
              />
            </LeftBlock>
            <RightBlock>
              <Title>{TwoColumnsContentOne.title}</Title>
              <Text>{TwoColumnsContentOne.subtitle}</Text>
              <LinkEl 
                href="#"
                className="disabled"
              >
                {TwoColumnsContentOne.linkText}
                <img src={ArrowRightB} style={{opacity: "48%", marginLeft: "7px", transition: "transform 0.2s ease-in-out"}} />
                </LinkEl>
            </RightBlock>
          </InnerWrapper>

          <InnerWrapper>
            <LeftBlock>
              <img 
                src={TwoColumnsContentTwo.image}
                alt={TwoColumnsContentTwo.alt}
              />
            </LeftBlock>
            <RightBlock>
              <Title>{TwoColumnsContentTwo.title}</Title>
              <Text>{TwoColumnsContentTwo.subtitle}</Text>
              <LinkEl 
                href="#"
                className="disabled"
              >
                {TwoColumnsContentTwo.linkText}
                <img src={ArrowRightB} style={{opacity: "48%", marginLeft: "7px", transition: "transform 0.2s ease-in-out"}} />
                </LinkEl>
            </RightBlock>
          </InnerWrapper>

          <InnerWrapper>
            <LeftBlock>
              <img 
                src={TwoColumnsContentThree.image}
                alt={TwoColumnsContentThree.alt}
              />
            </LeftBlock>
            <RightBlock>
              <Title>{TwoColumnsContentThree.title}</Title>
              <Text>{TwoColumnsContentThree.subtitle}</Text>
              <LinkEl 
                href="#"
                className="disabled"
              >
                {TwoColumnsContentThree.linkText}
                <img src={ArrowRightB} style={{opacity: "48%", marginLeft: "7px", transition: "transform 0.2s ease-in-out"}} />
                </LinkEl>
            </RightBlock>
          </InnerWrapper>
        </Container>
      </Wrapper>
  );
}
export default TwoColumns;
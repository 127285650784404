import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Header from "./Header";
import mainHero from "../assets/images/main-hero.svg";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: ${({ isMobile }) => (isMobile ? 0 : "12px")};
  height: ${({ isMobile }) => (isMobile ? "100vh" : "calc(100vh - 24px)")};
  border-radius: ${({ isMobile }) => (isMobile ? 0 : "24px")};
  background-color: #2f4c66;
  padding: 24px 40px;
  position: relative;
  justify-content: space-between;
  @media (min-width: 200px) and (max-width: 700px) {
    padding: 95px 16px 45px 16px;
    height: auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  user-select: none;
  animation-duration: 1s;
  animation-delay: 0.2s;
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  flex-basis: 20%;
  margin-bottom: 40px;
`;
const ButtonsBlock = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const ImageBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1366px;
  max-height: 400px;
  flex-basis: 40%;
  height: 40%;
`;

const TopBlock = styled.div`
  width: 100%;
  flex-basis: 40%;
`;

const TextTopTitle = styled.h3`
  color: #ffffff;
  font-size: 86px;
  margin: 0;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  text-transform: uppercase;
  @media (min-width: 320px) and (max-width: 1023px) {
    font-size: 44px;
    width: 100%;
    line-height: 50px;
  }
`;

const TextTitle = styled.h3`
  color: #ffffff;
  font-size: 64px;
  margin: 0;
  font-weight: 400;
  font-family: "Titillium Web", sans-serif;
  span {
    text-transform: uppercase;
  }
  @media (min-width: 320px) and (max-width: 1023px) {
    font-size: 44px;
    width: 100%;
    line-height: 50px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const MineHero = ({ christmas }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [isChristmas, setIsChristmas] = useState();

  const turnOff = useCallback(() => {
    setIsChristmas(isChristmas);
    christmas(false);
  }, [christmas, isChristmas]);

  return (
    <>
      <Header top={28}  />
      <Wrapper isMobile={isMobile}>
        <Content className="animate__animated animate__fadeIn">
          <TopBlock />
          <TextBlock>
            <ButtonsBlock>
              <TextTopTitle>Liquidity Rails</TextTopTitle>
              <TextTitle>
                For <span>Modern Capital</span>
              </TextTitle>
            </ButtonsBlock>
          </TextBlock>
          <ImageBlock>
            <Image src={mainHero} />
          </ImageBlock>
        </Content>
      </Wrapper>
    </>
  );
};

export default MineHero;

import React, { useState } from "react";
import Footer from "../components/Footer";
import styled from "styled-components";
import Header from "../components/Header";
import Goal from "../components/Goal";
import Mission from "../components/Mission";
import Team from "../components/Team";
import Modal from "../components/Modal";
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
  width: 100%;
`;
const TextTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 218px 24px 40px 24px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 134px 16px 40px 16px;
  }
  @media (min-width: 1024x) and (max-width: 1728px) {
    padding: 140px 24px 40px 24px;
  }
`;
const TextBlock = styled.div`
  background-color: #fff;
  padding: 120px 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -1px;
  border-top: 1px solid #deded8;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 16px;
  }
`;
const TopTextTitle = styled.h3`
  color: #0c0c0c;
  font-size: 85px;
  font-weight: 500;
  margin: 0;
  line-height: 1;
  font-family: "neueMachinaMedium";
  @media (min-width: 200px) and (max-width: 1023px) {
    font-size: 42px;
  }
  @media (min-width: 1024px) and (max-width: 1440px) {
    font-size: 63px;
  }
`;
const Text = styled.p`
  color: #0c0c0c;
  font-size: 36px;
  font-weight: 500;
  font-family: "DisplayProRoman";
  width: 37%;
  max-width: 653px;
  @media (min-width: 200px) and (max-width: 1023px) {
    margin-bottom: 40px;
    font-size: 24px;
    width: 100%;
  }
  @media (min-width: 1024px) and (max-width: 1727px) {
    margin-bottom: 60px;
  }
`;
const Valuate = styled.h1`
  color: #0c0c0c;
  font-size: 36px;
  font-family: "NeueMachinaMedium";
  font-size: 350px;
  line-height: 338px;
  display: flex;
  align-items: center;
  text-align: right;
  font-weight: 400;
  background: linear-gradient(90.32deg, #2888be 0.2%, #33aea9 99.67%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin: 0;
  @media (min-width: 200px) and (max-width: 1023px) {
    margin-bottom: 40px;
    font-size: 130px;
    line-height: 120px;
    margin: 0;
  }
  @media (min-width: 1024px) and (max-width: 1279px) {
    font-size: 180px;
  }
`;

const AboutUs = () => {
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);

  const handleChange = (person, visible) => {
    setData(person);
    setVisible(!visible);
  };

  if (visible) {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
    document.body.style.marginRight = "15px";
  } else {
    document.body.style.overflow = "auto";
    document.body.style.height = "auto";
    document.body.style.marginRight = 0;
  }

  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <>
      <Wrapper>
        <Header white />
        {isMobile ? (
          <TextTitleWrapper>
            <TopTextTitle>OUR FINANCIAL SYSTEMS ARE VANISHING.</TopTextTitle>{" "}
          </TextTitleWrapper>
        ) : (
          <TextTitleWrapper>
            <TopTextTitle>OUR FINANCIAL SYSTEMS</TopTextTitle>{" "}
            <TopTextTitle>ARE VANISHING.</TopTextTitle>
          </TextTitleWrapper>
        )}

        <TextBlock style={{ border: "none" }}>
          <Text>
            7 Billion Humans now live under a rapidly declining currency or
            authoritarian regime.
          </Text>
          <Valuate>7B</Valuate>
        </TextBlock>
        <TextBlock>
          <Text>$1 Trillion disappeared from banks in the past 6 months.</Text>
          <Valuate>$1T</Valuate>
        </TextBlock>
        <TextBlock>
          <Text>
            $27 Trillion in Tokenized market volume in bonds, funds and equities
            over the next four years.
          </Text>
          <Valuate>$27T</Valuate>
        </TextBlock>
        <Goal />
        <Mission />
        <Team onPerson={handleChange} />
        <Footer />
      </Wrapper>
      <Modal data={data} isVisible={visible} setVisible={setVisible} />
    </>
  );
};
export default AboutUs;

import React from "react";
import styled from "styled-components";
import arrow from "../assets/images/arrow-up.svg";

const Wrapper = styled.div`
  position: relative;
  background-color: #110f13;
  display: flex;
  flex-direction: column;
  padding-bottom: 120px;
  width: 100%;
  overflow: hidden;
  @media (min-width: 200px) and (max-width: 1023px) {
    padding-bottom: 60px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
`;
const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 50px;
`;
const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (min-width: 200px) and (max-width: 1023px) {
    flex-direction: column;
  }
`;

const Text = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: "DisplayProRoman";
  max-width: 402px;
  margin: 0;
  @media (min-width: 200px) and (max-width: 767px) {
    font-size: 14px;
  }
`;
const TextTop = styled.p`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-family: "DisplayProRoman";
  margin: 0;
  margin-bottom: 12px;
`;
const TextLink = styled(TextTop)`
  margin: 0;
  &:hover:after {
    width: 100%;
  }
  &:after {
    transition: all ease-in-out 0.2s;
    background: none repeat scroll 0 0 #24668e;
    content: "";
    display: block;
    height: 2px;
    width: 0;
  }
`;
const TextMiddle = styled.p`
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  font-family: "DisplayProRoman";
  margin: 0;
`;
const Sub = styled.p`
  color: #fff;
  font-size: 26px;
  font-weight: 500;
  font-family: "NeueMachinaMedium";
  margin: 10px 55px 0 0;
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 40px 0;
  @media (min-width: 200px) and (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const Line = styled.div`
  height: 1px;
  background: linear-gradient(90.32deg, #2888be 0.2%, #33aea9 99.67%);
  width: 100%;
`;
const TextTitle = styled.h3`
  z-index: 1;
  max-width: 560px;
  margin: 0;
  font-family: "NeueMachinaMedium";
  font-weight: 500;
  font-size: 54px;
  text-transform: uppercase;
  color: #ffffff;
  @media (min-width: 200px) and (max-width: 1023px) {
    font-size: 34px;
    margin: 20px 0;
  }
  @media (min-width: 1024px) and (max-width: 1727px) {
    font-size: 44px;
  }
`;
const LinkText = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 10px 0 28px 0;
  text-decoration: none;
`;

const Image = styled.img`
  width: 17px;
  height: 17px;
  margin-left: 4px;
`;

const Mission = () => {
  return (
    <Wrapper>
      <Content>
        <TextBlock>
          <TextTop>Our Mission:</TextTop>
          <TextMiddle>Maximize Human</TextMiddle>
          <TextMiddle>Freedom</TextMiddle>
        </TextBlock>
        <Line />
        <Block>
          <LeftSide>
            <Sub>01</Sub>
            <TextTitle>Product First, Mission Driven</TextTitle>
          </LeftSide>
          <Text>
            Our product and mission are one and the same: to maximize human
            freedom through advanced code and mathematics. We are dedicated
            builders, designers, and engineers, unwavering in our mission to
            deliver an exceptional product.
          </Text>
        </Block>
        <Line />
        <Block>
          <LeftSide>
            <Sub>02</Sub>
            <TextTitle>Boldness Is the Denominator</TextTitle>
          </LeftSide>
          <Text>
            Hesitation creates obstacles, boldness eliminates them. With
            audacious action, we embrace the challenges ahead, knowing that the
            path to success won't be easy.
          </Text>
        </Block>
        <Line />
        <Block>
          <LeftSide>
            <Sub>03</Sub>
            <TextTitle>Great Ideas are Priceless</TextTitle>
          </LeftSide>
          <Text>
            Great ideas, fueled by courageous action, have the power to shape
            the world. We relentlessly pursue technical superiority and stay at
            the forefront of emerging developments, knowing that great ideas
            transform reality.
          </Text>
        </Block>
        <Line />
        <Block>
          <LeftSide>
            <Sub>04</Sub>
            <TextTitle>Believe in Volume</TextTitle>
          </LeftSide>
          <Text>
            For rapid growth and leveraging economies of scale, we prioritize
            meticulous planning and proactive development. We understand that
            success lies in mastering massive technical infrastructure and
            harnessing the power of big numbers
          </Text>
        </Block>
        <Line />
        <Block>
          <LeftSide>
            <Sub>05</Sub>
            <TextTitle>Future Purpose, A Force Multiplier</TextTitle>
          </LeftSide>
          <Text>
            Embracing a forward-looking mindset fuels exponential growth. Our
            unwavering focus lies in the accomplishments we can achieve 5, 10,
            20+ years from now, prioritizing long-term impact over short-term
            victories.
          </Text>
        </Block>
        <LinkText href="/Culture">
          <TextLink>Dive deeper into our culture</TextLink>
          <Image src={arrow} />
        </LinkText>
      </Content>
    </Wrapper>
  );
};
export default Mission;

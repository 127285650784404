import React from "react";
import styled from "styled-components";
import "../index.css";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Title = styled.h5`
  color: #0c0c0c;
  font-size: ${(props) => (props.boldTitle ? "44px" : "36px")};
  font-weight: 500;
  line-height: 43px;
  margin-top: 120px;
  margin-bottom: 30px;
  letter-spacing: -0.5px;
`;
const SubTitle = styled.p`
  color: #0c0c0c;
  font-size: 21px;
  font-weight: 600;
  margin: 0;
  letter-spacing: -0.5px;
`;
const Divider = styled.div`
  background-color: #cecece;
  height: 1px;
  width: 100px;
  margin-top: 10px;
`;
const SubTitleTwo = styled(SubTitle)`
  margin-top: 30px;
`;

const Text = styled.p`
  color: #0c0c0c;
  font-size: 19px;
  font-weight: 500;
  line-height: 160%;
  margin-bottom: 0;
  margin-top: 30px;
  letter-spacing: -0.5px;
  span {
    font-weight: 600;
  }
`;
const BoldText = styled(Text)`
  font-size: 22px;
`;
const LinkText = styled.a`
  color: #0c0c0c;
  font-size: 19px;
  font-weight: 500;
  line-height: 160%;
  letter-spacing: -0.5px;
  text-decoration: underline;
  cursor: pointer;
`;
const LinkTextColor = styled(LinkText)`
  color: #225d87;
`;

const Paragraph = ({
  title,
  text_1,
  text_2,
  text_3,
  text_4,
  text_5,
  text_6,
  id,
  outerRef,
  linkText,
  boldText,
  ethos,
  boldTitle,
  subTitle,
  subTitle_2,
  boldStart,
  boldStart_2,
  boldStart_3,
  divider_1,
  divider_2,
}) => {
  return (
    <Wrapper className="groteskRoman" id={id} ref={outerRef}>
      {title ? (
        <Title>{title}</Title>
      ) : boldTitle ? (
        <Title boldTitle>{boldTitle}</Title>
      ) : null}
      {subTitle ? (
        <>
          <SubTitle>{subTitle}</SubTitle>
          {divider_1 ? <Divider /> : null}
        </>
      ) : null}
      {subTitle_2 ? (
        <>
          <SubTitleTwo>{subTitle_2}</SubTitleTwo>
          {divider_2 ? <Divider /> : null}
        </>
      ) : null}
      {boldText ? (
        <BoldText>{boldText}</BoldText>
      ) : ethos ? (
        <Text>
          The enemy is{" "}
          <LinkTextColor
            href="https://blog.siddhaprotocol.com/blockchain-rising/"
            target="_blank"
          >
            beliefs and opinions
          </LinkTextColor>
          . The path is{" "}
          <LinkTextColor
            href="https://blog.siddhaprotocol.com/zero-knowledge-proofs-zkps/"
            target="_blank"
          >
            maximizing freedom
          </LinkTextColor>{" "}
          via the immutable laws of code and mathematics; fostering compounded
          rates of progress to amplify human potential.
        </Text>
      ) : boldStart ? (
        <Text>
          <span>{boldStart}</span>
          {text_1}
        </Text>
      ) : text_1 ? (
        <Text boldTitle>{text_1}</Text>
      ) : null}
      {linkText ? (
        <Text>
          If you share our commitment to building a better future, please apply
          on our{" "}
          <LinkText href="https://angel.co/company/siddha/jobs" target="_blank">
            careers page
          </LinkText>
          .
        </Text>
      ) : null}
      {boldStart_2 ? (
        <Text>
          <span>{boldStart_2}</span>
          {text_2}
        </Text>
      ) : text_2 ? (
        <Text>{text_2}</Text>
      ) : null}
      {boldStart_3 ? (
        <Text>
          <span>{boldStart_3}</span>
          {text_3}
        </Text>
      ) : text_3 ? (
        <Text>{text_3}</Text>
      ) : null}
      {text_4 ? <Text>{text_4}</Text> : null}
      {text_5 ? <Text>{text_5}</Text> : null}
      {text_6 ? <Text>{text_6}</Text> : null}
    </Wrapper>
  );
};
export default Paragraph;
